import { Controller } from "@hotwired/stimulus";
import * as animate from "../helpers/animate";

export default class extends Controller {
  static targets = [
    "countrySelect",
    "stateSelect",
    "stateTextField",
    "zipTextField",
    "zipLabel",
    "stateSelectContainer",
    "stateTextFieldContainer",
  ];

  connect() {
    this.toggleStateAndZip();
  }

  changedCountry(_event) {
    this.toggleStateAndZip();
  }

  toggleStateAndZip() {
    if (!this.hasStateSelectTarget) {
      return;
    }

    if (this.isCountryUsa) {
      this.stateSelectTarget.disabled = false;
      animate.toggle(this.stateSelectContainerTarget, true, { duration: 0 });

      this.stateTextFieldTarget.disabled = true;
      animate.toggle(this.stateTextFieldContainerTarget, false, { duration: 0 });

      this.zipLabelTarget.classList.add("required");
    } else {
      this.stateSelectTarget.disabled = true;
      animate.toggle(this.stateSelectContainerTarget, false, { duration: 0 });

      this.stateTextFieldTarget.disabled = false;
      animate.toggle(this.stateTextFieldContainerTarget, true, { duration: 0 });

      this.zipLabelTarget.classList.remove("required");
    }
  }

  get isCountryUsa() {
    const country =
      this.countrySelectTarget.tagName === "SELECT"
        ? this.countrySelectTarget.value
        : this.countrySelectTarget.textContent;
    return country === "US";
  }
}
