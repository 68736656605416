import { GeoJSON } from "ol/format";
import { featureCollection } from "@turf/helpers";
import buffer from "@turf/buffer";
import Feature from "ol/Feature";

import { isMultiPolygon } from "../ol-helpers";

export default class BufferBuilder {
  translateOpenLayersFeaturesToTurf(feature) {
    const projectionMap = this.#getProjectionMap;
    const projectionTurf = this.#getProjectionTurf;

    const format = new GeoJSON();

    const collection = [];
    collection.push(
      format.writeFeatureObject(feature, {
        dataProjection: projectionTurf,
        featureProjection: projectionMap,
      }),
    );

    return featureCollection(collection);
  }

  buildBufferFeatures(featuresCollection, size) {
    const result = [];
    featuresCollection.features.forEach((feature) => {
      const featureAsFeatureCollection = featureCollection([feature]);
      const collectionOfSingleBufferedFeature = buffer(featureAsFeatureCollection, size, {
        units: "feet",
        steps: 2,
      });
      result.push(collectionOfSingleBufferedFeature.features);
    });

    return featureCollection(result.flat());
  }

  translateTurfFeaturesToModels(features) {
    const projectionMap = this.#getProjectionMap;
    const projectionTurf = this.#getProjectionTurf;

    const format = new GeoJSON();

    // Some roof planes when combined with large setbacks may create multiple
    // distinct buffered interior polygons. Those are returned as MultiPolygons.
    const initialResult = format.readFeatures(features, {
      dataProjection: projectionTurf,
      featureProjection: projectionMap,
    });

    // This converts any MultiPolygons into separate simple polygons and flattens
    // the collection
    this.buildModelsFromTurfFeatures(initialResult);
  }

  mapMultiPolygon(feature) {
    let polygonFeatures;
    if (isMultiPolygon(feature)) {
      const geometry = feature.getGeometry();

      polygonFeatures = geometry.getPolygons().map((polygon) => {
        return new Feature({ geometry: polygon });
      });
    } else {
      polygonFeatures = [feature];
    }

    return polygonFeatures;
  }

  get #getProjectionMap() {
    let projectionMap = "EPSG:3857";
    const projection = this.map.getView().getProjection();
    if (projection !== undefined) {
      projectionMap = projection.getCode();
    }
    return projectionMap;
  }

  get #getProjectionTurf() {
    return "EPSG:4326";
  }
}
