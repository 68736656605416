import { Draw } from "ol/interaction";

import { scaleLineStyle } from "../../styles/scale-line";
import { SCALE_LINE_DATA_TYPE } from "../../data-types";
import { constraintGeometryFunction, withConstraintMixin } from "../helpers/constraint";

export default class Base {
  constructor(controller) {
    this.controller = controller;
    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;
    this.scaleLinesVectorSource = this.mapManager.scaleLinesVectorSource;

    this.initConstraint();
  }

  add() {
    if (this.currentScaleLineInteraction) return;

    this.attachConstraintListeners();
    this.clearCurrentInteraction();

    this.currentScaleLineInteraction = new Draw({
      source: this.scaleLinesVectorSource,
      type: "LineString",
      maxPoints: 2,
      style: scaleLineStyle("drawing"),
      geometryFunction: (coordinates, geometry) =>
        constraintGeometryFunction(coordinates, geometry, this.map, this.constrainPressed, "LineString"),
    });
    this.map.addInteraction(this.currentScaleLineInteraction);
    this.currentScaleLineInteraction.on("drawstart", this.onDrawStart);
    this.currentScaleLineInteraction.on("drawend", this.onDrawEnd);
  }

  remove() {
    if (!this.currentScaleLineInteraction) return;

    this.detachConstraintListeners();
    this.clearCurrentInteraction();
  }

  clearCurrentInteraction() {
    if (this.currentScaleLineInteraction) {
      this.map.removeInteraction(this.currentScaleLineInteraction);
      delete this.currentScaleLineInteraction;
      this.currentScaleLineInteraction = undefined;
    }
  }

  onDrawStart = (_event) => {
    this.clearOutLastFeature();
  };

  onDrawEnd = (event) => {
    const scaleLineFeature = event.feature;
    scaleLineFeature.set("dataType", SCALE_LINE_DATA_TYPE);
  };

  finish() {
    if (!this.currentScaleLineInteraction) return;

    this.currentScaleLineInteraction.finishDrawing();
    this.clearOutLastFeature();
  }

  clearOutLastFeature() {
    const drawingSource = this.mapManager.scaleLinesVectorSource;
    const features = drawingSource.getFeatures();
    const lastFeature = features[features.length - 1];
    drawingSource.removeFeature(lastFeature);
  }
}

Object.assign(Base.prototype, withConstraintMixin);
