// Suppress React DevTools message - implemented in polyfills for earliest possible execution
if (typeof window !== "undefined") {
  // Create a completely mocked DevTools hook that does nothing
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__ = {
    isDisabled: true,
    supportsFiber: true,
    inject: function () {},
    onCommitFiberRoot: function () {
      return true;
    },
    onCommitFiberUnmount: function () {},
  };
}
