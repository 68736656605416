import Feature from "ol/Feature";
import LineString from "ol/geom/LineString";
import { Point } from "ol/geom";

import { logger, warn } from "../../../../helpers/app";
import { OBSTRUCTION_DATA_TYPE, ROOF_PLANE_DATA_TYPE, ROOF_SECTION_DATA_TYPE } from "../../data-types";

const DEBUG_LOGGING = false;

export default class Base {
  constructor(controller) {
    this.controller = controller;
    this.mapManager = controller.mapManager;
    this.sourceFeatures = [];
    this.settings = controller.settings;
  }

  add(features, from) {
    logger(`[shapeGuideLines] add`, from, features);

    const featuresToAdd = Array.isArray(features) ? features : [features];

    featuresToAdd.forEach((feature) => {
      if (this.sourceFeatures.includes(feature)) return;
      if (!this.#validFeature(feature)) return;

      this.sourceFeatures.push(feature);
    });

    this.render();
  }

  update(features = [], from) {
    logger(`[shapeGuideLines] update`, from, features);

    const featuresToUpdate = Array.isArray(features) ? features : [features];

    featuresToUpdate.forEach((feature) => {
      if (!this.sourceFeatures.includes(feature)) {
        this.sourceFeatures.push(feature);
      }
    });

    this.render();
  }

  remove(features, from) {
    logger(`[shapeGuideLines] remove`, from, features);

    const featuresToRemove = Array.isArray(features) ? features : [features];

    this.sourceFeatures = this.sourceFeatures.filter((f) => !featuresToRemove.includes(f));

    this.render();
  }

  clear() {
    this.sourceFeatures = [];
    this.clearFeatures();
  }

  clearFeatures() {
    this.mapManager.shapeGuideLinesVectorSource.clear();
  }

  render() {
    this.clearFeatures();

    this.shapeGuideLineLines = new Set();
    this.shapeGuideLineLineFeatures = [];

    const seenVertices = new Set();
    this.#sourceFeatureVertices.forEach((vertex) => {
      try {
        const key = vertex.join(",");
        if (!seenVertices.has(key)) {
          seenVertices.add(key);
          this.#addShapeGuideLineAtVertex(vertex);
        }
      } catch (e) {
        debugger;
      }
    });

    this.#addSnapPoints();
  }

  // rotate, zoom, or drag
  onMapChange = () => {
    this.render();
  };

  #validFeature(feature) {
    const geometry = feature.getGeometry();
    const drawing = feature.get("drawing");
    if (!drawing && (!geometry || geometry.getArea() <= 0)) {
      // Sometimes OpenLayers will add a feature with an area of 0 to a vector source, but
      // then do cleanup later and remove the feature.  We are tying into some of the
      // vector sources' addfeature events, and for some reason, the cleanup that removes
      // the feature doesn't trigger the removefeature event.  So we need to check for and
      // ignore these features.
      logger("[shapeGuideLines]", feature, "ignoring feature with area 0");

      return false;
    }

    const dataType = feature.get("dataType");

    if (!dataType) {
      warn(`[shapeGuideLines] empty data type`, feature);
      return false;
    }

    const validDataTypes = [ROOF_PLANE_DATA_TYPE, ROOF_SECTION_DATA_TYPE, OBSTRUCTION_DATA_TYPE];
    if (!validDataTypes.includes(dataType)) {
      warn(`[shapeGuideLines] invalid data type`, dataType);
      return false;
    }

    return this.#isFeatureInMapLayers(feature);
  }

  #isFeatureInMapLayers(feature) {
    if (feature.get("drawing")) return true;

    if (!this.mapManager || !this.mapManager.map) return false;

    const mapLayers = this.mapManager.map
      .getLayers()
      .getArray()
      .filter((layer) => layer.getSource && typeof layer.getSource === "function");

    return mapLayers.some((layer) => {
      if (!layer.getVisible()) return false;

      const source = layer.getSource();
      if (!source || !source.getFeatures || typeof source.getFeatures !== "function") {
        return false;
      }

      return source.getFeatures().some((f) => f === feature);
    });
  }

  #addShapeGuideLineAtVertex(vertex) {
    if (!vertex) return;

    const [topBottomLine, leftRightLine] = this.#shapeGuideLineLineCoordinatesForVertex(vertex);

    this.addShapeGuideLineLine(topBottomLine, "lineVertical");
    this.addShapeGuideLineLine(leftRightLine, "lineHorizontal");
  }

  addShapeGuideLineLine(coordinates, type) {
    const key = coordinates.map((c) => c.join(",")).join(";");
    if (this.shapeGuideLineLines.has(key)) return;

    this.shapeGuideLineLines.add(key);
    const geometry = new LineString(coordinates);
    const feature = new Feature({ geometry, type });

    this.shapeGuideLineLineFeatures.push(feature);
    this.#addFeature(feature);
  }

  get #sourceFeatureVertices() {
    return this.#sourceFeaturesRenderedOnMap.flatMap((f) => {
      const geometry = f.getGeometry();

      if (geometry.getType() === "Polygon") {
        return geometry.getLinearRing(0).getCoordinates();
      } else if (geometry.getType() === "LineString") {
        return geometry.getCoordinates();
      } else if (geometry.getType() === "Point") {
        return [];
      } else {
        debugger;
      }
    });
  }

  get #sourceFeaturesRenderedOnMap() {
    return this.sourceFeatures.filter((feature) => {
      const shownOnMap = this.#isFeatureInMapLayers(feature);

      let showFeature = true;

      const dataType = feature.get("dataType");
      if (dataType === ROOF_PLANE_DATA_TYPE && !this.settings.showShapeGuideLinesForRoofPlanes) {
        showFeature = false;
      }

      if (dataType === ROOF_SECTION_DATA_TYPE && !this.settings.showShapeGuideLinesForRoofSections) {
        showFeature = false;
      }

      if (dataType === OBSTRUCTION_DATA_TYPE && !this.settings.showShapeGuideLinesForObstructions) {
        showFeature = false;
      }

      return shownOnMap && showFeature;
    });
  }

  #addFeature(feature) {
    this.mapManager.shapeGuideLinesVectorSource.addFeature(feature);
  }

  #shapeGuideLineLineCoordinatesForVertex(vertex) {
    const map = this.mapManager.map;
    const [sizeX, sizeY] = map.getSize();

    const vertexPixels = map.getPixelFromCoordinate(vertex);
    const [vertexX, vertexY] = vertexPixels;

    const topPixels = [vertexX, 0];
    const bottomPixels = [vertexX, sizeY];

    const leftPixels = [0, vertexY];
    const rightPixels = [sizeX, vertexY];

    const topCoordinates = map.getCoordinateFromPixel(topPixels);
    const bottomCoordinates = map.getCoordinateFromPixel(bottomPixels);
    const leftCoordinates = map.getCoordinateFromPixel(leftPixels);
    const rightCoordinates = map.getCoordinateFromPixel(rightPixels);

    return [
      [topCoordinates, bottomCoordinates],
      [leftCoordinates, rightCoordinates],
    ];
  }

  // You can snap to the shapeGuideLine lines but when the lines intersect you need another feature
  // to snap to the intersection, otherwise the snap will follow one line edge or the other.
  #addSnapPoints() {
    const snapPointsSet = new Set();
    const snapPoints = [];

    for (let i = 0; i < this.shapeGuideLineLineFeatures.length; i++) {
      const line1 = this.shapeGuideLineLineFeatures[i].getGeometry().getCoordinates();
      for (let j = i + 1; j < this.shapeGuideLineLineFeatures.length; j++) {
        const line2 = this.shapeGuideLineLineFeatures[j].getGeometry().getCoordinates();
        const intersection = this.#getSegmentIntersection(line1, line2);
        if (intersection) {
          const key = intersection.join(",");
          if (!snapPointsSet.has(key)) {
            snapPointsSet.add(key);
            snapPoints.push(intersection);
          }
        }
      }
    }

    if (DEBUG_LOGGING) logger(`[shapeGuideLine] addSnapPoints (${snapPoints.length})`, snapPoints);

    snapPoints.forEach((vertex) => {
      const geometry = new Point(vertex);
      const feature = new Feature({ geometry, type: "snapPoint" });
      this.#addFeature(feature);
    });
  }

  #getSegmentIntersection(segment1, segment2) {
    const [[x1, y1], [x2, y2]] = segment1;
    const [[x3, y3], [x4, y4]] = segment2;

    const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
    if (denominator === 0) return null;

    const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / denominator;
    const u = ((x1 - x3) * (y1 - y2) - (y1 - y3) * (x1 - x2)) / denominator;

    if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
      const intersectX = x1 + t * (x2 - x1);
      const intersectY = y1 + t * (y2 - y1);
      return [intersectX, intersectY];
    }

    return null;
  }
}
