import Base from "../../../../da/map/interaction-managers/draw/base";
import { ROOF_PLANE_DATA_TYPE } from "../../../../da/map/data-types";
import { createRoofPlane } from "../../modification-helpers/roof-plane";
import { updateProjectOriginAndCartesianCoordinatesForChildren } from "../../modification-helpers/project";

export default class RoofPlanes extends Base {
  get targetDrawingSource() {
    return this.mapManager.roofPlanesVectorSource;
  }

  get featureDataType() {
    return ROOF_PLANE_DATA_TYPE;
  }

  createModelFromFeature(feature) {
    createRoofPlane(feature, this.controller.project, this.mapModelSynchronizer, this.mapManager);
  }

  add() {
    super.add();
    this.currentDrawInteraction.on("drawend", this.updateProjectOriginAndCartesianCoordinates);
  }

  updateProjectOriginAndCartesianCoordinates = (_event) => {
    updateProjectOriginAndCartesianCoordinatesForChildren(this.controller.project);
  };

  clearCurrentInteraction() {
    super.clearCurrentInteraction();
    if (this.currentDrawInteraction) {
      this.currentDrawInteraction.un("drawend", this.updateProjectOriginAndCartesianCoordinates);
    }
  }

  featureSpecificDrawEndProcessing(_event) {
    this.controller.shapeGuideLinesInteractionManager.remove();
  }
}
