import * as api from "../../../helpers/api";
import { sentryException } from "../../../config/sentry";

// A wrapper for Google Maps related API interactions:
// * Typing in location search field calls Google Places auto-complete suggestions
// * Hitting return in location search field or clicking the search button hits Google Places API and returns a result
//   * Result is shown on Google Map
// * The map can be panned or zoomed
//   * Dragging the map keeps the pin in the middle and updates the lat/lng
export default class {
  statusesToReportToApiErrorHandler = [
    google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT,
    google.maps.places.PlacesServiceStatus.REQUEST_DENIED,
    google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR,
  ];

  defaultMapSettings = {
    mapId: "property-search-map",
    mapTypeId: "satellite",
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
  };

  placesFields = ["name", "geometry", "place_id", "formatted_address", "address_component"];

  constructor({
    connectingLat,
    connectingLng,
    connectingZoom,
    mapContainerElement,
    locationSearchField,
    mapLocationSearchResultCallback,
    mapReverseGeolocationResultCallback,
    mapCenterChangedCallback,
    mapShowErrorCallback,
  }) {
    this.connectingLat = connectingLat;
    this.connectingLng = connectingLng;
    this.connectingZoom = connectingZoom;

    this.mapContainerElement = mapContainerElement;
    this.locationSearchField = locationSearchField;

    this.mapLocationSearchResultCallback = mapLocationSearchResultCallback;
    this.mapReverseGeolocationResultCallback = mapReverseGeolocationResultCallback;
    this.mapCenterChangedCallback = mapCenterChangedCallback;
    this.mapShowErrorCallback = mapShowErrorCallback;

    this.initMap();
    this.initMapMarker();
    this.initMapServices();
    this.initAutoComplete();
  }

  initMap() {
    this.currentPlace = null;
    this.hasBeenDragged = false;

    this.map = new google.maps.Map(this.mapContainerElement, {
      ...this.defaultMapSettings,
      center: { lat: this.connectingLat, lng: this.connectingLng },
      zoom: this.connectingZoom,
    });
    // Without this the map may show at 45 degree angle when zooming in
    this.map.setTilt(0);

    this.map.addListener("center_changed", () => {
      this.marker.position = this.map.center;
      this.mapCenterChangedCallback(this.lat, this.lng);
    });

    this.map.addListener("drag", () => {
      this.hasBeenDragged = true;
    });
  }

  initMapMarker() {
    this.marker = new google.maps.marker.AdvancedMarkerElement({
      map: this.map,
      position: this.map.center,
    });
  }

  initMapServices() {
    this.maxZoomService = new google.maps.MaxZoomService();
    this.placesService = new google.maps.places.PlacesService(this.map);
    this.geocoder = new google.maps.Geocoder();
  }

  initAutoComplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.locationSearchField, {
      fields: this.placesFields,
    });
    this.autocomplete.bindTo("bounds", this.map);
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }

  get lat() {
    return this.map.center.lat();
  }

  get lng() {
    return this.map.center.lng();
  }

  get zoom() {
    return this.map.getZoom();
  }

  currentPositionDistanceFromLatLngInFeet(lat, lng) {
    const parsedLat = parseFloat(lat);
    const parsedLng = parseFloat(lng);

    if (
      Number.isNaN(parsedLat) ||
      Number.isNaN(parsedLng) ||
      parsedLat < -90.0 ||
      parsedLat > 90.0 ||
      parsedLng < -180.0 ||
      parsedLng > 180.0
    ) {
      const message = `[Handled Error] trying to check distance for invalid lat/lng: ${lat}, ${lng}`;
      console.log(message);
      sentryException(message);
      return 0;
    }
    if (!this.map.center) {
      // Including the map in the message just gives you an [Object object]. Stringifying it doesn't
      // work because it is cyclic. Including a custom replacer didn't seem worth it.
      const message = "[Handled Error] trying to check distance with no map center.";
      console.log(message);
      sentryException(message);
      return 0;
    }

    const myLatLng = new google.maps.LatLng(parsedLat, parsedLng);
    return this.distanceInFeetBetweenLatLng(this.map.center, myLatLng);
  }

  distanceInFeetBetweenLatLng(latLng1, latLng2) {
    let meters = 0;

    try {
      meters = google.maps.geometry.spherical.computeDistanceBetween(latLng1, latLng2);
      // TODO: remove this once we ensure that errors are gone
      console.log(`Successfully calculated distance between locations: "${latLng1}" to "${latLng2}" = ${meters}m`);
    } catch (error) {
      const message = `Google maps error: ${error}`;
      const extras = {
        latLng1: JSON.stringify(latLng1),
        latLng2: JSON.stringify(latLng2),
      };
      console.log(message);
      sentryException(message, extras);
    }

    return meters / 0.3048;
  }

  setCenter(latLng) {
    this.map.setCenter(latLng);
  }

  zoomInMaximumAmount() {
    // Sometimes the max zoom doesn't appear to fully zoom in.  Adding a bit of a delay to see if it
    // improves things.
    setTimeout(() => {
      this.maxZoomService.getMaxZoomAtLatLng(this.map.center, (response) => {
        if (response.status !== "OK") {
          console.error("Error in MaxZoomService");
          return;
        }
        this.map.setZoom(response.zoom);
      });
    }, 500);
  }

  disableInteraction() {
    this.enableInteraction(false);
  }

  enableInteraction(enabled = true) {
    this.map.setOptions({
      draggable: enabled,
      zoomControl: enabled,
      scrollwheel: enabled,
      disableDoubleClickZoom: !enabled,
    });
    if (enabled) {
      google.maps.event.clearListeners(this.map, "click");
    } else {
      this.map.addListener("click", () => {
        alert("The map is disabled while choosing an address.");
      });
    }
  }

  locationSearch(searchText) {
    if (searchText === "") return;
    this.hasBeenDragged = false;

    this.currentPlace = null;
    // Sometimes the event fires before the place has registered causing an error, so we wait a bit
    setTimeout(() => {
      if (this.marker) {
        this.marker.map = null;
      }
      const place = this.autocomplete.getPlace();

      // If the user has entered an address partial and hasn't selected something from the autocomplete
      // list before submitting
      if (!place || !place.geometry) {
        this.placesService.findPlaceFromQuery(
          {
            query: searchText,
            fields: ["formatted_address", "place_id"],
          },
          this.showFirstPlaceFromLocationSearch,
        );
      } else {
        this.showPlaceOnMap(place);
      }
    }, 300);
  }

  showFirstPlaceFromLocationSearch = (results, status) => {
    if (this.statusNotOk(status)) return;

    const placeId = results[0].place_id;
    this.placesService.getDetails({ placeId, fields: this.placesFields }, (place, status) => {
      if (this.statusNotOk(status)) return;
      this.showPlaceOnMap(place);
    });
  };

  statusNotOk(status) {
    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      this.mapShowErrorCallback("That does not appear to be a valid location. Enter an address or a valid zipcode.");
      if (this.statusesToReportToApiErrorHandler.includes(status)) {
        const handledPrefix = status === "UNKNOWN_ERROR" ? "[Handled Error] " : "";

        api.errorHandler(`${handledPrefix}Property Search: Google Maps API Places Service is ${status}`);
      }
      return true;
    }
    return false;
  }

  showPlaceOnMap(place) {
    if (!place.geometry) return;

    const { location, viewport } = place.geometry;
    // If the place has a geometry, then present it on a map.
    if (viewport) {
      this.map.fitBounds(viewport);
    } else {
      this.map.setCenter(location);
      this.map.setZoom(10);
    }
    this.marker.position = this.map.center;
    this.marker.map = this.map;
    this.currentPlace = place;
    this.zoomInMaximumAmount();
    this.mapLocationSearchResultCallback(place);
  }

  geocodeCurrentPosition() {
    const latLng = this.map.center;
    this.geocoder.geocode({ location: latLng }, (results, status) => {
      let errorMsg = "";
      if (status === "OK") {
        if (results[0]) {
          this.mapReverseGeolocationResultCallback({ googlePlace: results[0] });
          return;
        }
        errorMsg = "Geocode yielded no results.";
      } else {
        errorMsg = `Geocoder failed due to: ${status}.`;
      }
      api.errorHandler(`[Handled Error] Reverse geocode for ${latLng.lat()}, ${latLng.lng()}. ${errorMsg}`);
      this.mapReverseGeolocationResultCallback({ googlePlace: false });
    });
  }

  resetToConnectingValues() {
    this.map.setCenter({ lat: this.connectingLat, lng: this.connectingLng });
    this.zoomInMaximumAmount();
  }
}
