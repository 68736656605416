import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image", "magnifier"];

  static values = {
    zoomLevel: { type: Number, default: 5 },
    magnifierWidth: { type: Number, default: 150 },
    magnifierHeight: { type: Number, default: 150 },
  };

  magnifierActive = false;

  connect() {
    this.setupMagnifier();
  }

  disconnect() {
    this.removeMagnifierHandlers();
  }

  setupMagnifier() {
    if (this.hasImageTarget && this.hasMagnifierTarget) {
      this.imageTarget.addEventListener("mousemove", this.handleMouseMove);
      this.imageTarget.addEventListener("mouseenter", this.handleMouseEnter);
      this.imageTarget.addEventListener("mouseleave", this.handleMouseLeave);
    }
  }

  removeMagnifierHandlers() {
    if (this.hasImageTarget) {
      this.imageTarget.removeEventListener("mousemove", this.handleMouseMove);
      this.imageTarget.removeEventListener("mouseenter", this.handleMouseEnter);
      this.imageTarget.removeEventListener("mouseleave", this.handleMouseLeave);
    }
  }

  handleMouseEnter = () => {
    this.magnifierActive = true;
    this.magnifierTarget.classList.remove("ir-magnifier__overlay--hidden");
  };

  handleMouseLeave = () => {
    this.magnifierActive = false;
    this.magnifierTarget.classList.add("ir-magnifier__overlay--hidden");
  };

  handleMouseMove = (e) => {
    if (!this.magnifierActive || !this.hasMagnifierTarget) return;

    const { left, top, width, height } = this.imageTarget.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;

    const zoomLevel = this.zoomLevelValue;

    const magnifierWidth = this.magnifierWidthValue;
    const magnifierHeight = this.magnifierHeightValue;
    const magnifierLeft = Math.max(0, Math.min(x - magnifierWidth / 2, width - magnifierWidth));
    const magnifierTop = Math.max(0, Math.min(y - magnifierHeight / 2, height - magnifierHeight));

    this.magnifierTarget.style.left = `${magnifierLeft}px`;
    this.magnifierTarget.style.top = `${magnifierTop}px`;

    const imgUrl = this.imageTarget.src;
    const imgX = x * zoomLevel - magnifierWidth / 2;
    const imgY = y * zoomLevel - magnifierHeight / 2;

    this.magnifierTarget.style.backgroundImage = `url(${imgUrl})`;
    this.magnifierTarget.style.backgroundSize = `${width * zoomLevel}px ${height * zoomLevel}px`;
    this.magnifierTarget.style.backgroundPosition = `-${imgX}px -${imgY}px`;
  };
}
