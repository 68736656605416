import { Controller } from "@hotwired/stimulus";

export default class GoogleMapFullPage extends Controller {
  static values = { lat: Number, lng: Number, zoom: Number };

  connect() {
    const latLng = { lat: this.latValue, lng: this.lngValue };

    this.map = new google.maps.Map(this.element, {
      mapId: "google-map-full-page",
      center: latLng,
      zoom: this.zoomValue,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      disableDefaultUI: true,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    });

    this.map.setTilt(0);

    // Hiding this for now.  I don't think we need a marker on the full page map.
    // This is mainly used for the areal image view in the project report for
    // non-image based maps.  I don't think the marker is helpful, but in case
    // there's a desire to bring it back, I'll leave this commented out.
    // new google.maps.marker.AdvancedMarkerElement({
    //   position: latLng,
    //   map: this.map,
    // });
  }
}
