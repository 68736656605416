import DaBase from "../../../../da/map/interaction-managers/draw/base";
import { ROOF_SECTION_DATA_TYPE } from "../../../../da/map/data-types";
import { createRoofSection } from "../../modification-helpers/roof-section";

export default class RoofSections extends DaBase {
  get targetDrawingSource() {
    return this.mapManager.roofSectionsVectorSource;
  }

  get featureDataType() {
    return ROOF_SECTION_DATA_TYPE;
  }

  createModelFromFeature(feature) {
    createRoofSection(feature, this.controller.project, this.mapModelSynchronizer, this.mapManager);
  }
}
