import { Controller } from "@hotwired/stimulus";
import React from "react";
import ReactDOMServer from "react-dom/server";

export default class extends Controller {
  static targets = ["mapContainer"];

  mapId = "distributors-map";

  connect() {
    this.getLocations();

    this.initGoogleMap();
  }

  getLocations() {
    this.locations = JSON.parse(this.element.dataset.locations);
  }

  initGoogleMap = () => {
    if (window.google === undefined) {
      setTimeout(this.initGoogleMap, 50);
      return;
    }

    this.map = new google.maps.Map(this.mapContainerTarget, {
      center: new google.maps.LatLng(9.973271851273907, 48.593512174935576),
      zoom: 20,
      mapId: this.mapId,
    });
    this.addMarkersToMap();
  };

  addMarkersToMap() {
    this.bounds = new google.maps.LatLngBounds();
    this.locations.forEach((location) => {
      this.addLocationMarkerToMap(location);
    });
    this.map.fitBounds(this.bounds);
  }

  addLocationMarkerToMap(location) {
    if (location.lat === null || location.lng === null) return;

    const latLng = new google.maps.LatLng(location.lat, location.lng);
    this.bounds.extend(latLng);

    const advancedMarker = new google.maps.marker.AdvancedMarkerElement({
      position: latLng,
      map: this.map,
    });

    const locationInfoPopup = new google.maps.InfoWindow({
      content: this.locationInfoPopupContent(location),
    });

    google.maps.event.addListener(advancedMarker, "click", () => {
      locationInfoPopup.open({
        anchor: advancedMarker,
        map: this.map,
      });
    });
  }

  quoteLink = (distributor_name, email, city, state) => (
    <a
      href="#"
      className="ir-link--orange"
      data-action="distributors--quote-links#request"
      data-distributor-name={distributor_name}
      data-distributor-email={email}
      data-distributor-city={city}
      data-distributor-state={state}
    >
      <i className="fas fa-file-invoice-dollar" />
      &nbsp;Request quote
    </a>
  );

  header = (url, name) => (
    <h5>
      {url !== "" && (
        <a href={url} target="_blank">
          {name}
        </a>
      )}
      {url === "" && name}
    </h5>
  );

  locationInfoPopupContent(location) {
    const { distributor_name, distributor_url, address, city, state, zipcode, phone, email } = location;

    const content = (
      <div>
        {this.header(distributor_url, distributor_name)}
        {email !== "" && <a href={`mailto:${email}`}>{email}</a>}
        {email !== "" && <br />}
        {address !== "" && <span>{address}</span>}
        {address !== "" && <br />}
        {city !== "" && <span>{city}</span>}
        &nbsp;
        {state !== "" && <span>{state}</span>}
        &nbsp;
        {zipcode !== "" && <span>{zipcode}</span>}
        &nbsp;
        <br />
        {phone !== "" && <span>{phone}</span>}
        {phone !== "" && <br />}
        <br />
        {this.quoteLink(distributor_name, email, city, state)}
      </div>
    );
    return ReactDOMServer.renderToString(content);
  }
}
