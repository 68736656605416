import { featureEdgesIntersect, markIllegalIfFeatureHasPointsInsideFeature } from "./polygon-helpers";
import { pointOutsidePolygon, pointInPolygon } from "../../../helpers/point-in-polygon";
import FeatureExpander from "../buffering/feature-expander";

export default class RoofSectionLegalityChecker {
  constructor(roofPlaneFeature, roofSectionsFeatures, mapModelSynchronizer) {
    this.roofPlaneFeature = roofPlaneFeature;
    this.roofSectionsFeatures = roofSectionsFeatures;
    this.mapModelSynchronizer = mapModelSynchronizer;

    this.roofPlane = mapModelSynchronizer.getRoofPlaneForFeature(roofPlaneFeature);
    this.collectDefaultRoofSectionsFeatures();
  }

  markIllegalPolygons(justCompletedFeature = undefined) {
    let features = this.siblingRoofSections();
    if (justCompletedFeature) {
      features = [...features, justCompletedFeature];
    }

    this.cleanLegalityFlags(features);

    for (let i = 0; i < features.length; i++) {
      const feature = features[i];

      // if roof section is default, then can't be illegal or encroaching
      if (!feature.get("custom")) continue;

      // if it has any vertices outside of it's roof plane
      const hasVertexOutsideOfRoofPlane = this.roofSectionOutsideOfRoofPlane(feature);
      if (hasVertexOutsideOfRoofPlane) {
        feature.set("illegalShape", true);
      }

      // If feature edges intersect other edges of same feature, it should be illegal
      if (featureEdgesIntersect(feature, feature)) {
        feature.set("illegalShape", true);
      }

      this.checkAgainstSiblingRoofSections(feature, features);
      if (!hasVertexOutsideOfRoofPlane) {
        // if it has any vertices outside of the roof plane, then it's illegal, not encroaching
        this.checkForEncroachment(feature);
      }
    }

    this.saveIllegalityIntoModels(justCompletedFeature);
  }

  collectDefaultRoofSectionsFeatures() {
    this.defaultRoofSectionsFeatures = this.roofPlane.defaultRoofSections.map((drs) => {
      if (drs.active) {
        return this.mapModelSynchronizer.getFeatureForRoofSection(drs);
      } else {
        // inactive default roof sections aren't shown, so they don't have features
        // this creates a feature, but doesn't add it to any vector source
        return this.mapModelSynchronizer.createFeatureForModel(drs);
      }
    });
  }

  checkAgainstSiblingRoofSections(feature, features) {
    for (let j = 0; j < features.length; j++) {
      const otherFeature = features[j];

      if (feature.get("uuid") === otherFeature.get("uuid")) continue;

      // it has an edge that intersects the edge of another active roof section
      // it has 1+ vertices that are inside another active roof section
      if (featureEdgesIntersect(feature, otherFeature)) {
        feature.set("illegalShape", true);
        otherFeature.set("illegalShape", true);
      } else {
        markIllegalIfFeatureHasPointsInsideFeature(feature, otherFeature);
      }
    }
  }

  checkForEncroachment(feature) {
    this.expandDefaultRoofSections();

    if (
      this.encroachmentAnyVertexOutsideOfAllDefaultRoofSections(feature) ||
      this.encroachmentAnyDefaultRoofSectionIntersection(feature) ||
      this.encroachmentAnyDefaultRoofSectionVertexInsideFeature(feature)
    ) {
      feature.set("encroaching", true);
    }
  }

  // When OL buffers an inward pointing corner, it creates vertices that are marginally out
  // of alignment with the incoming / outgoing edges. This was causing custom roof sections
  // that followed the guidelines to intersect and show encroachment. To avoid that, we'll
  // expand the default roof section by 0.001 feet and check the custom roof section against
  // that polygon (which is slightly closer to the roof plane and creates something marginally
  // smaller than the setback)
  expandDefaultRoofSections() {
    this.expandedDefaultRoofSectionFeatures = this.defaultRoofSectionsFeatures.map((drsf) => {
      return this.expandedDefaultRoofSection(drsf);
    });
  }

  expandedDefaultRoofSection(defaultRoofSectionFeature) {
    const outset = 0.001; // 0.001 ft = 0.012 inches
    const featureExpander = new FeatureExpander(
      this.mapModelSynchronizer.controller.mapManager.map,
      defaultRoofSectionFeature,
      outset,
    );
    featureExpander.expand();
    return featureExpander.expandedFeature;
  }

  encroachmentAnyVertexOutsideOfAllDefaultRoofSections(feature) {
    console.log("encroachmentAnyVertexOutsideOfAllDefaultRoofSections");
    const geometry = feature.getGeometry();
    const coordinates = geometry.getLinearRing(0).getCoordinates();

    return coordinates.some((c) => {
      return this.expandedDefaultRoofSectionFeatures.every((drsf) => {
        return this.vertexOutsideOfRoofSection(c, drsf);
      });
    });
  }

  encroachmentAnyDefaultRoofSectionIntersection(feature) {
    console.log("encroachmentAnyDefaultRoofSectionIntersection");
    return this.expandedDefaultRoofSectionFeatures.some((drsf) => {
      return featureEdgesIntersect(feature, drsf);
    });
  }

  encroachmentAnyDefaultRoofSectionVertexInsideFeature(feature) {
    console.log("encroachmentAnyDefaultRoofSectionVertexInsideFeature");
    return this.expandedDefaultRoofSectionFeatures.some((drsf) => {
      const drsfGeometry = drsf.getGeometry();
      const coordinates = drsfGeometry.getCoordinates()[0];

      return coordinates.some((c) => {
        return this.vertexInsideOfRoofSection(c, feature);
      });
    });
  }

  siblingRoofSections() {
    return this.roofSectionsFeatures.filter((rs) => {
      return rs.get("roofPlaneUuid") === this.roofPlane.uuid;
    });
  }

  cleanLegalityFlags(features) {
    features.forEach((feature) => {
      feature.set("illegalShape", false);
      feature.set("encroaching", false);
    });
  }

  roofSectionOutsideOfRoofPlane(feature) {
    return featureEdgesIntersect(feature, this.roofPlaneFeature) || this.firstVertexOutsideOfPolygon(feature);
  }

  vertexOutsideOfRoofSection(vertex, feature) {
    const featurePolygon = feature.getGeometry().getCoordinates();
    const isOutsideFeature = pointOutsidePolygon(vertex, featurePolygon);

    return isOutsideFeature;
  }

  vertexInsideOfRoofSection(vertex, feature) {
    const featurePolygon = feature.getGeometry().getCoordinates();
    const isInsideFeature = pointInPolygon(vertex, featurePolygon);

    return isInsideFeature;
  }

  firstVertexOutsideOfPolygon(feature) {
    const coordinates = feature.getGeometry().getLinearRing(0).getCoordinates();
    const firstVertex = coordinates[0];
    const roofPlanePolygon = this.roofPlaneFeature.getGeometry().getCoordinates();
    const isOutsideRoofPlane = pointOutsidePolygon(firstVertex, roofPlanePolygon);

    return isOutsideRoofPlane;
  }

  saveIllegalityIntoModels(justCompletedFeature) {
    const extraFeatures = justCompletedFeature ? [justCompletedFeature] : undefined;
    this.mapModelSynchronizer.updateAllRoofSectionsIllegalityFromFeature(extraFeatures);
  }
}
