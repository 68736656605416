import { SETTINGS, SHOW_SHAPE_GUIDE_LINES_OPTIONS } from "./index";

export default class SettingChangeApplier {
  static call(controller, change) {
    new SettingChangeApplier(controller, change).call();
  }

  constructor(controller, change) {
    this.controller = controller;
    this.change = change;
    this.setting = change.setting;
    this.from = change.from;
    this.to = change.to;

    this.mapManager = controller.mapManager;

    this.shapeGuideLinesInteractionManager = controller.shapeGuideLinesInteractionManager;
    this.drawInteractionManager = controller.drawInteractionManager;
    this.selectInteractionManager = controller.selectInteractionManager;
  }

  call() {
    if (this.setting === SETTINGS.SHOW_SHAPE_GUIDE_LINES) {
      this.#applyShowGuideLinesChange();
    } else if (
      this.setting === SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_PLANES ||
      this.setting === SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_SECTIONS ||
      this.setting === SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_OBSTRUCTIONS
    ) {
      this.#applyShowGuideLinesFiltersChange();
    } else {
      throw new Error(`Unknown setting change: ${this.change}`);
    }
  }

  #applyShowGuideLinesChange() {
    if (!this.shapeGuideLinesInteractionManager) return;

    const drawingFeature = this.drawInteractionManager.currentDrawInteraction?.feature;

    let features = [];

    switch (this.to) {
      case SHOW_SHAPE_GUIDE_LINES_OPTIONS.NEVER:
        this.shapeGuideLinesInteractionManager.clear();
        return;

      case SHOW_SHAPE_GUIDE_LINES_OPTIONS.ALWAYS:
        const vectorSources = [
          this.mapManager.roofPlanesVectorSource,
          this.mapManager.roofSectionsVectorSource,
          this.mapManager.obstructionsVectorSource,
        ];

        features = vectorSources.flatMap((vectorSource) => {
          if (!vectorSource) return [];
          return vectorSource.getFeatures();
        });

        const currentDrawingFeature = this.controller.drawInteractionManager.currentDrawInteraction?.feature;
        if (currentDrawingFeature) features.push(currentDrawingFeature);
        break;

      case SHOW_SHAPE_GUIDE_LINES_OPTIONS.DRAWING:
        if (drawingFeature) features.push(drawingFeature);
        break;

      case SHOW_SHAPE_GUIDE_LINES_OPTIONS.DRAWING_AND_SELECTING:
        features = this.selectInteractionManager.selectedFeatures;
        if (drawingFeature) features.push(drawingFeature);
        break;

      default:
        throw new Error(`Unknown show guide lines option: ${this.to}`);
    }

    this.shapeGuideLinesInteractionManager.clear();

    if (features.length > 0) {
      this.shapeGuideLinesInteractionManager.add(features, "SettingsChangeApplier");
    }
  }

  #applyShowGuideLinesFiltersChange() {
    const showShapeGuideLinesSetting = this.controller.settings.showShapeGuideLines;
    const neverShow = showShapeGuideLinesSetting === SHOW_SHAPE_GUIDE_LINES_OPTIONS.NEVER;
    if (!this.shapeGuideLinesInteractionManager || neverShow) return;

    this.shapeGuideLinesInteractionManager.render();
  }
}
