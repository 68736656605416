import DaBase from "../../../../da/map/interaction-managers/draw/base";
import { OBSTRUCTION_DATA_TYPE } from "../../../../da/map/data-types";
import { createObstruction } from "../../modification-helpers/obstruction";

export default class RoofPlanes extends DaBase {
  get targetDrawingSource() {
    return this.mapManager.obstructionsVectorSource;
  }

  get featureDataType() {
    return OBSTRUCTION_DATA_TYPE;
  }

  createModelFromFeature(feature) {
    const obstruction = createObstruction(feature, this.controller.project, this.mapModelSynchronizer, this.mapManager);
    this.controller.bxLayoutEditorObstructionHeightsFormOutlet.add(obstruction);
  }
}
