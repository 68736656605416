import { Translate } from "ol/interaction";
import { Collection } from "ol";

import { isARoofPlane, isARoofSection, isAMeasure, isAnObstruction, isAThermalExpansion } from "../../ol-helpers";
import { logger } from "../../../../helpers/app";
import { calculateMeasureSlopeAdjustedDistance } from "../measure/helpers";

export default class Base {
  constructor(controller) {
    this.controller = controller;
    this.settings = controller.settings;

    this.project = controller.project;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;

    this.currentTranslationInteraction = undefined;
  }

  add() {
    this.clearCurrentInteraction();

    this.currentTranslationInteraction = new Translate({
      features: this.translatableFeatures,
      filter: this.isFeatureTranslatable,
    });

    this.currentTranslationInteraction.on("translatestart", this.translateStart);

    // We do this in the "translating" event handler because the "translatestart"
    // event gets fired even when a user only selects a feature but doesn't move it
    this.currentTranslationInteraction.on("translating", (event) => {
      const { features } = event;

      if (!this.translatingFeatures) {
        this.translatingFeatures = features.getArray();
        this.addRemoveShapeGuideLines(this.translatingFeatures, "remove");
      }
    });

    this.currentTranslationInteraction.on("translateend", this.translateEnd);
    this.currentTranslationInteraction.on("translateend", (_event) => {
      if (this.translatingFeatures) {
        this.addRemoveShapeGuideLines(this.translatingFeatures, "add");
        this.translatingFeatures = undefined;
      }
    });

    this.map.addInteraction(this.currentTranslationInteraction);
  }

  addRemoveShapeGuideLines(features, type) {
    if (!this.controller.shapeGuideLinesInteractionManager) return;

    const polygons = features.filter((f) => f.getGeometry().getType() === "Polygon");
    this.shapeGuideLinesCall(type, polygons, "addRemoveShapeGuideLines");
  }

  get showShapeGuideLinesWhenTranslating() {
    return this.controller.shapeGuideLinesInteractionManager && this.settings.showShapeGuideLinesWhenSelecting;
  }

  shapeGuideLinesCall(method, features, from) {
    if (!this.showShapeGuideLinesWhenTranslating) return;

    this.controller.shapeGuideLinesInteractionManager[method](features, `translate ${from}`);
  }

  isFeatureTranslatable(_feature) {
    return true;
  }

  get translatableFeatures() {
    const features = this.controller.selectInteractionManager.selectionCollection.getArray();
    const filteredFeatures = features.filter((f) => !isAThermalExpansion(f));
    return new Collection(filteredFeatures);
  }

  remove() {
    this.clearCurrentInteraction();
  }

  clearCurrentInteraction() {
    if (this.currentTranslationInteraction) {
      this.map.removeInteraction(this.currentTranslationInteraction);
      this.currentTranslationInteraction.un("translateend", this.translateEnd);

      delete this.currentTranslationInteraction;
      this.currentTranslationInteraction = undefined;
    }
  }

  translateStart = (event) => {
    const { features } = event;

    if (!features) return;
    features.forEach((feature) => this.translateStartForFeature(feature));
  };

  translateEnd = (event) => {
    const { features, startCoordinate, coordinate } = event;

    if (!features) return;
    if (startCoordinate[0] === coordinate[0] && startCoordinate[0] === coordinate[0]) return;

    features.forEach((feature) => {
      if (isARoofPlane(feature) || isARoofSection(feature) || isAnObstruction(feature)) {
        this.translateEndForFeature(feature);
      } else if (isAMeasure(feature)) {
        logger("Translating measure");
        feature.set("slopeAdjustedDistance", calculateMeasureSlopeAdjustedDistance(feature, this.controller));
      }
    });

    this.controller.markDirty();
    this.alreadyRemovedArray = false;
  };

  translateEndForFeature(_feature) {
    // override in sub class
  }

  translateStartForFeature(_feature) {
    // override in sub class
  }
}
