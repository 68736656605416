import * as localStore from "../../../helpers/local-store";

const LOCAL_STORAGE_PREFIX = "le--settings";

export const SETTINGS_EVENT = {
  CHANGED: "settingChanged",
};

export const SETTINGS = {
  SHOW_SHAPE_GUIDE_LINES: "showShapeGuideLines",
  SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_PLANES: "showShapeGuideLinesForRoofPlanes",
  SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_SECTIONS: "showShapeGuideLinesForRoofSections",
  SHOW_SHAPE_GUIDE_LINES_FOR_OBSTRUCTIONS: "showShapeGuideLinesForObstructions",
};

export const SHOW_SHAPE_GUIDE_LINES_OPTIONS = {
  DRAWING: "drawing",
  DRAWING_AND_SELECTING: "drawing_and_selecting",
  ALWAYS: "always",
  NEVER: "never",
};

const SHOW_SHAPE_GUIDE_LINES_DEFAULT = SHOW_SHAPE_GUIDE_LINES_OPTIONS.DRAWING;
const SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_PLANES_DEFAULT = true;
const SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_SECTIONS_DEFAULT = true;
const SHOW_SHAPE_GUIDE_LINES_FOR_OBSTRUCTIONS_DEFAULT = true;

export default class LayoutEditorSettings {
  get showShapeGuideLines() {
    return this.#get(SETTINGS.SHOW_SHAPE_GUIDE_LINES) || SHOW_SHAPE_GUIDE_LINES_DEFAULT;
  }

  get showShapeGuideLinesWhenDrawing() {
    return [
      SHOW_SHAPE_GUIDE_LINES_OPTIONS.DRAWING,
      SHOW_SHAPE_GUIDE_LINES_OPTIONS.DRAWING_AND_SELECTING,
      SHOW_SHAPE_GUIDE_LINES_OPTIONS.ALWAYS,
    ].includes(this.showShapeGuideLines);
  }

  get showShapeGuideLinesWhenSelecting() {
    return [SHOW_SHAPE_GUIDE_LINES_OPTIONS.DRAWING_AND_SELECTING, SHOW_SHAPE_GUIDE_LINES_OPTIONS.ALWAYS].includes(
      this.showShapeGuideLines,
    );
  }

  get showShapeGuideLinesAlways() {
    return [SHOW_SHAPE_GUIDE_LINES_OPTIONS.ALWAYS].includes(this.showShapeGuideLines);
  }

  set showShapeGuideLines(value) {
    this.#set(SETTINGS.SHOW_SHAPE_GUIDE_LINES, value);
  }

  get showShapeGuideLinesForRoofPlanes() {
    const storedValue = this.#get(SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_PLANES);
    return storedValue !== null ? storedValue === "true" : SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_PLANES_DEFAULT;
  }

  set showShapeGuideLinesForRoofPlanes(value) {
    this.#set(SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_PLANES, String(value));
  }

  get showShapeGuideLinesForRoofSections() {
    const storedValue = this.#get(SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_SECTIONS);
    return storedValue !== null ? storedValue === "true" : SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_SECTIONS_DEFAULT;
  }

  set showShapeGuideLinesForRoofSections(value) {
    this.#set(SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_SECTIONS, String(value));
  }

  get showShapeGuideLinesForObstructions() {
    const storedValue = this.#get(SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_OBSTRUCTIONS);
    return storedValue !== null ? storedValue === "true" : SHOW_SHAPE_GUIDE_LINES_FOR_OBSTRUCTIONS_DEFAULT;
  }

  set showShapeGuideLinesForObstructions(value) {
    this.#set(SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_OBSTRUCTIONS, String(value));
  }

  #get(key) {
    return localStore.get(`${LOCAL_STORAGE_PREFIX}--${key}`);
  }

  #set(key, value) {
    localStore.set(`${LOCAL_STORAGE_PREFIX}--${key}`, value);
  }
}
