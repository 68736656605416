import { Map, View } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { fromLonLat, getPointResolution, toLonLat } from "ol/proj";
import { defaults as interactionDefaults } from "ol/interaction";
import MouseWheelZoom from "ol/interaction/MouseWheelZoom";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";

import { roofSectionsStyle } from "./styles/roof-sections";
import { roofPlaneStyle } from "./styles/roof-planes";
import { measureDrawStyle } from "./styles/measures";
import { visualMarkerStyle } from "./styles/visual-markers";
import { shapeGuideLinesStyle } from "./styles/shape-guide-lines";
import { setbacksStyle } from "./styles/setbacks";
import {
  ROOF_PLANE_DATA_TYPE,
  ROOF_SECTION_DATA_TYPE,
  MEASURE_DATA_TYPE,
  SHAPE_GUIDE_LINE_DATA_TYPE,
  SETBACK_DATA_TYPE,
  VISUAL_MARKER_DATA_TYPE,
  GUIDE_LINE_DATA_TYPE,
  GUIDE_LINES_INTERSECTION_CIRCLE_DATA_TYPE,
  BUILDING_IMAGE_PLACEMENT_DATA_TYPE,
} from "./data-types";
import DefaultRoofSectionsBuilder from "./default-roof-sections-builder";
import * as events from "./events";
import { debounce } from "lodash";
import { guideLinesIntersectionCirclesStyle, guideLinesStyle } from "../../pr/map/styles/guide-lines";
import TileLayerFactory from "./tile-layers-factory";
import { logger } from "../../helpers/app";

export default class MapManager {
  constructor(controller) {
    this.controller = controller;

    this.bingApiKey = controller.bingApiKey;
    this.azureApiKey = controller.azureApiKey;
    this.openLayersTileProvider = controller.openLayersTileProvider;
    this.project = controller.project;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.saveEditorLocation = this.mapModelSynchronizer.saveEditorLocation;
    this.setProjectSiteValuesOnMoveEnd = true;

    this.compassTarget = controller.compassTarget;

    const rotationKey = controller.isViewerPage ? "viewerRotation" : "editorRotation";
    this.rotateCompassTo(this.project.projectSite[rotationKey]);

    window.map = this.map; // for dev/debug purposes

    this.dispatchAfterMapFeaturesRendering = debounce(this.dispatchAfterMapFeaturesRendering, 50);

    this.buildingImagePlacementLayers = [];
    this.buildingImagePlacementFeatures = [];

    // The shape guide line interaction manager will be initialized after the map manager is initialized
    // so we need to keep track of the features that need to have shape guide lines rendered for them
    // until the shape guide line interaction manager is initialized
    this.featuresToRenderShapeGuideLinesFor = [];
  }

  dispatchBeforeMapFeaturesRendering(eventOptions = {}) {
    events.dispatchBeforeMapFeaturesRendering(this.map, eventOptions);
  }

  dispatchAfterMapFeaturesRendering(eventOptions = {}) {
    events.dispatchAfterMapFeaturesRendering(this.map, eventOptions);
  }

  buildMap(overrides) {
    const defaults = {
      target: this.controller.mapTarget,
      layers: [this.tileLayer],
      view: this.mapView,
      controls: [], // Controls initially added to the map. If not specified, module:ol/control~defaults is used.
      interactions: interactionDefaults({ doubleClickZoom: false }), // turning off double click zoom (it's awkward with drawing)
    };
    const map = new Map({ ...defaults, ...overrides });

    map.on("rendercomplete", (_event) => {
      if (this.controller.shapeGuideLinesInteractionManager && this.featuresToRenderShapeGuideLinesFor.length > 0) {
        this.shapeGuideLinesCall("add", this.featuresToRenderShapeGuideLinesFor, "map rendercomplete");
        this.featuresToRenderShapeGuideLinesFor = [];
      }
    });

    return map;
  }

  addMouseWheelZoomInteraction() {
    if (this.scrollWheelInteractionAdded) return;

    this.map.addInteraction(new MouseWheelZoom());
    this.scrollWheelInteractionAdded = true;
  }

  get mapView() {
    const { projectSite } = this.project;

    if (this.controller.isViewerPage) {
      return new View({
        constrainRotation: false, // Remove snapping around 0 degrees (makes rotation behave funky)
        center: fromLonLat([projectSite.effectiveViewerLng, projectSite.effectiveViewerLat]),
        zoom: projectSite.effectiveViewerZoom,
        rotation: projectSite.effectiveViewerRotation,
      });
    } else {
      return new View({
        constrainRotation: false, // Remove snapping around 0 degrees (makes rotation behave funky)
        center: fromLonLat([projectSite.effectiveEditorLng, projectSite.effectiveEditorLat]),
        zoom: projectSite.effectiveEditorZoom,
        rotation: projectSite.effectiveEditorRotation,
      });
    }
  }

  get tileLayer() {
    const provider = this.openLayersTileProvider;
    const apiKey = this.openLayersTileProvider === "bing" ? this.bingApiKey : this.azureApiKey;
    const factory = new TileLayerFactory(provider, apiKey);
    return factory.tileLayer;
  }

  buildRoofPlanesVectorSource() {
    this.roofPlanesVectorSource = new VectorSource();
    this.roofPlanesVectorSource.on("addfeature", this.autoDeleteFlaggedFeatures);
    this.roofPlanesVectorSource.on("addfeature", (event) => {
      this.shapeGuideLinesCall("add", event.feature, "roofPlanesVectorSource addfeature");
    });
    this.roofPlanesVectorSource.on("removefeature", (event) => {
      this.shapeGuideLinesCall("remove", event.feature, "roofPlanesVectorSource removefeature");
    });

    const features = this.mapModelSynchronizer.featuresForDisplayableRoofPlanes();
    if (this.controller.settings.showShapeGuideLinesAlways) this.featuresToRenderShapeGuideLinesFor.push(...features);
    this.roofPlanesVectorSource.addFeatures(features);
  }

  get showShapeGuideLinesForRenderedPolygons() {
    return this.controller.shapeGuideLinesInteractionManager && this.controller.settings.showShapeGuideLinesAlways;
  }

  shapeGuideLinesCall(method, features, from) {
    if (!this.showShapeGuideLinesForRenderedPolygons) return;

    this.controller.shapeGuideLinesInteractionManager[method](features, `render ${from}`);
  }

  // The vector layer for roof plane drawing, which uses the vector source for roof section data
  get roofPlanesVectorLayer() {
    if (!this.memoRoofPlaneVectorLayer) {
      this.memoRoofPlaneVectorLayer = new VectorLayer({
        source: this.roofPlanesVectorSource,
        style: (feature) => roofPlaneStyle(feature, this.controller, this.project),
        zIndex: 10,
      });
      this.memoRoofPlaneVectorLayer.set("dataType", ROOF_PLANE_DATA_TYPE);
    }
    return this.memoRoofPlaneVectorLayer;
  }

  clearRoofPlaneFeatures() {
    this.roofPlanesVectorSource.clear();
  }

  renderRoofPlaneFeatures() {
    const features = this.mapModelSynchronizer.featuresForDisplayableRoofPlanes();
    this.roofPlanesVectorSource.addFeatures(features);
  }

  // Data for the roof section vector layer, contains roof section polygon data
  buildRoofSectionsVectorSource() {
    this.roofSectionsVectorSource = new VectorSource();
    this.roofSectionsVectorSource.on("addfeature", this.autoDeleteFlaggedFeatures);
    this.roofSectionsVectorSource.on("addfeature", (event) => {
      this.shapeGuideLinesCall("add", event.feature, "roofSectionsVectorSource addfeature");
    });
    this.roofSectionsVectorSource.on("removefeature", (event) => {
      this.shapeGuideLinesCall("remove", event.feature, "roofSectionsVectorSource removefeature");
    });

    const features = this.mapModelSynchronizer.featuresForDisplayableRoofSections();
    if (this.controller.settings.showShapeGuideLinesAlways) this.featuresToRenderShapeGuideLinesFor.push(...features);
    this.roofSectionsVectorSource.addFeatures(features);
  }

  renderRoofSectionFeatures() {
    const features = this.mapModelSynchronizer.featuresForDisplayableRoofSections();
    this.roofSectionsVectorSource.addFeatures(features);
  }

  // The vector layer for roof section drawing, which uses the vector source for roof section data
  get roofSectionsVectorLayer() {
    if (!this.memoRoofSectionsVectorLayer) {
      this.memoRoofSectionsVectorLayer = new VectorLayer({
        source: this.roofSectionsVectorSource,
        style: (feature) => roofSectionsStyle(feature, this.controller, this.project),
        zIndex: 30,
      });
      this.memoRoofSectionsVectorLayer.set("dataType", ROOF_SECTION_DATA_TYPE);
    }
    return this.memoRoofSectionsVectorLayer;
  }

  clearRoofSectionFeatures() {
    this.roofSectionsVectorSource.clear();
  }

  get roofSectionsFeatures() {
    return this.roofSectionsVectorSource.getFeatures();
  }

  // The vector layer for setbacks drawing, which uses the vector source for setbacks data
  get setbacksVectorLayer() {
    if (!this.memoSetbacksVectorLayer) {
      this.memoSetbacksVectorLayer = new VectorLayer({
        source: this.setbacksVectorSource,
        style: (feature) => setbacksStyle(feature),
        zIndex: 20,
      });
      this.memoSetbacksVectorLayer.set("dataType", SETBACK_DATA_TYPE);
    }
    return this.memoSetbacksVectorLayer;
  }

  buildNewDefaultRoofSectionsAndSetbacks() {
    // have to add this after the map is created because we need the map to
    // calculate the default roof sections
    this.roofSectionsBuilder = new DefaultRoofSectionsBuilder(this.controller, this.map, this.roofPlanesFeatures);

    // TODO: Optimization only add setbacks that are newly created for
    //       newly constructed default roof sections.
    this.updateSetbacksVectorSource();
  }

  // Data for the setbacks vector layer, contains setbacks polygon data
  buildSetbacksVectorSource() {
    this.setbacksVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.loadSetbacks(),
    });
  }

  addSetbacksVectorLayer() {
    this.map.addLayer(this.setbacksVectorLayer);
  }

  updateSetbacksVectorSource() {
    this.buildSetbacksVectorSource();
    this.setbacksVectorLayer.setSource(this.setbacksVectorSource);
  }

  addRoofSectionsAndSetbacksVectorLayers() {
    this.map.addLayer(this.roofSectionsVectorLayer);
    this.map.addLayer(this.setbacksVectorLayer);
  }

  buildMeasuresVectorSource() {
    this.measuresVectorSource = new VectorSource();
  }

  get measuresVectorLayer() {
    if (!this.memoMeasuresVectorLayer) {
      this.memoMeasuresVectorLayer = new VectorLayer({
        source: this.measuresVectorSource,
        style: (feature) => measureDrawStyle(feature, this.map),
        zIndex: 100,
      });
      this.memoMeasuresVectorLayer.set("dataType", MEASURE_DATA_TYPE);
    }
    return this.memoMeasuresVectorLayer;
  }

  addMeasuresVectorLayer() {
    this.map.addLayer(this.measuresVectorLayer);
  }

  buildShapeGuideLinesVectorSource() {
    this.shapeGuideLinesVectorSource = new VectorSource({});
  }

  get shapeGuideLinesVectorLayer() {
    if (!this.memoShapeGuideLinesVectorLayer) {
      this.memoShapeGuideLinesVectorLayer = new VectorLayer({
        source: this.shapeGuideLinesVectorSource,
        style: (feature) => shapeGuideLinesStyle(feature),
        zIndex: 30,
      });
      this.memoShapeGuideLinesVectorLayer.set("dataType", SHAPE_GUIDE_LINE_DATA_TYPE);
    }
    return this.memoShapeGuideLinesVectorLayer;
  }

  onMoveEnd = (event) => {
    this.onMoveEndHandler(event);
  };

  onMoveEndHandler(event) {
    // This gets called automatically when the map gets loaded for some reason
    // TODO: figure out why this is happening
    if (!this.firstMoveEnd) {
      this.firstMoveEnd = true;
      return false;
    }

    if (this.controller.skipNextMapMoveEndEvent) {
      this.controller.skipNextMapMoveEndEvent = false;
      return false;
    }

    this.updateShapeGuideLines();

    if (this.setProjectSiteValuesOnMoveEnd) {
      this.saveEditorLocation();
      this.controller.markDirty();
    }

    return true;
  }

  get roofPlanesFeatures() {
    return this.roofPlanesVectorSource.getFeatures();
  }

  // Useful for manually triggering a call of the style functions for all roof planes
  forceRedrawRoofPlanes() {
    this.forceRedrawFeatures(this.roofPlanesVectorSource);
  }

  forceRedrawObstructions() {
    this.forceRedrawFeatures(this.obstructionsVectorSource);
  }

  forceRedrawRoofSections() {
    this.forceRedrawFeatures(this.roofSectionsVectorSource);
  }

  forceRedrawSetbacks() {
    this.forceRedrawFeatures(this.setbacksVectorSource);
  }

  forceRedrawMeasures() {
    this.forceRedrawFeatures(this.measuresVectorSource);
  }

  forceRedrawFeatures(vectorSource) {
    const features = vectorSource?.getFeatures() || [];
    features.forEach((feature) => feature.dispatchEvent("change"));
  }

  rotateBy(degrees) {
    const radians = (degrees * Math.PI) / 180;
    const view = this.map.getView();

    const currentRotation = view.getRotation();
    const newRotation = currentRotation + radians;

    this.rotateTo(newRotation);
  }

  rotateTo(newRotation) {
    const view = this.map.getView();
    const startAngle = view.getRotation();

    view.animate({ rotation: newRotation, duration: 100 });

    this.rotateCompassTo(newRotation);
    this.updateShapeGuideLines();
    this.forceRedrawRoofPlanes();
    this.forceRedrawRoofSections();
    this.forceRedrawSetbacks();
    this.forceRedrawMeasures();

    this.rotateBuildingImagePlacementsAfterMapRotation(startAngle, newRotation);
  }

  rotateCompassTo(radians) {
    const degrees = (radians * 180) / Math.PI;
    this.compassTarget.style.transform = `rotate(${degrees}deg)`;
    const compassMarkers = Array.from(this.compassTarget.querySelectorAll(".ol-map__compass__marker"));
    compassMarkers.forEach((marker) => {
      marker.style.transform = `rotate(${-degrees}deg)`;
    });
  }

  setCenter(coordinate) {
    const view = this.map.getView();
    view.setCenter(coordinate);
  }

  zoomBy(amount) {
    const newZoom = this.zoom + amount;
    this.zoomTo(newZoom);
  }

  zoomTo(newZoom) {
    const view = this.map.getView();

    view.animate({ zoom: newZoom, duration: 50 });
    this.updateShapeGuideLines();
  }

  setZoomRotationAndPosition(zoom, rotation, coordinate) {
    const view = this.map.getView();
    view.setZoom(zoom);
    view.setRotation(rotation);
    view.setCenter(coordinate);
  }

  updateShapeGuideLines() {
    if (this.controller.shapeGuideLinesInteractionManager) {
      setTimeout(this.controller.shapeGuideLinesInteractionManager.onMapChange, 500);
    }
  }

  get zoom() {
    return this.map.getView().getZoom();
  }

  get rotation() {
    return this.map.getView().getRotation();
  }

  get rotationDegrees() {
    return this.rotation * (180 / Math.PI);
  }

  onClick(method) {
    this.map.on("click", method);
  }

  unClick(method) {
    this.map.un("click", method);
  }

  get visualMarkersVectorLayer() {
    if (!this.memoVisualMarkersVectorLayer) {
      this.visualMarkersVectorSource = new VectorSource();
      this.memoVisualMarkersVectorLayer = new VectorLayer({
        source: this.visualMarkersVectorSource,
        style: (feature) => visualMarkerStyle(feature),
        zIndex: 100,
      });
      this.memoVisualMarkersVectorLayer.set("dataType", VISUAL_MARKER_DATA_TYPE);
    }
    return this.memoVisualMarkersVectorLayer;
  }

  addVisualMarker(coordinate) {
    const point = new Point(coordinate);
    const feature = new Feature({ geometry: point });
    this.visualMarkersVectorSource.addFeature(feature);
    return feature;
  }

  clearVisualMarkers() {
    this.visualMarkersVectorSource.clear();
  }

  highlightSegment(uuid, highlight = true) {
    this.highlightFeatureInVectorSource(this.segmentsVectorSource, uuid, highlight);
  }

  highlightPanel(uuid, highlight = true) {
    this.highlightFeatureInVectorSource(this.panelsVectorSource, uuid, highlight);
  }

  highlightFeatureInVectorSource(vectorSource, uuid, highlight) {
    const feature = vectorSource.getFeatures().find((f) => f.get("uuid") === uuid);

    if (!feature) {
      console.log("Could not find feature with that uuid", uuid);
      return;
    }

    feature.set("highlighted", highlight);
  }

  addMouseWheelZoomWhenCtrlKeyPressedInteraction() {
    const mouseWheelZoomInteraction = new MouseWheelZoom({
      condition: (event) => {
        return event.originalEvent.ctrlKey;
      },
    });
    this.map.addInteraction(mouseWheelZoomInteraction);
  }

  keepTrackOfDrawingCoordinates = (event) => {
    this.addDrawingCoordinateToProjectModel(event);
  };

  addDrawingCoordinateToProjectModel(event) {
    if (!this.controller.drawInteractionManager || !this.controller.drawInteractionManager.currentDrawInteraction) {
      return;
    }

    this.controller.project.addDrawingCoordinate(event.coordinate);
  }

  buildGuideLinesVectorSource() {
    this.guideLinesVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.loadGuideLines(),
    });
  }

  reloadGuideLineFeatures() {
    this.guideLinesVectorSource.clear();
    this.mapModelSynchronizer.loadGuideLines().forEach((feature) => {
      this.guideLinesVectorSource.addFeature(feature);
    });

    this.guideLinesIntersectionCirclesVectorSource.clear();
    this.mapModelSynchronizer
      .loadGuideLinesIntersectionCircles(
        this.guideLinesVectorSource.getFeatures(),
        this.roofPlanesVectorSource.getFeatures(),
      )
      .forEach((feature) => {
        this.guideLinesIntersectionCirclesVectorSource.addFeature(feature);
      });
  }

  get guideLinesVectorLayer() {
    if (!this.memoGuideLinesVectorLayer) {
      this.memoGuideLinesVectorLayer = new VectorLayer({
        source: this.guideLinesVectorSource,
        style: (feature) => guideLinesStyle(feature, this.map, this.project.displayableRoofPlanes),
        zIndex: 25,
      });
      this.memoGuideLinesVectorLayer.set("dataType", GUIDE_LINE_DATA_TYPE);
    }
    return this.memoGuideLinesVectorLayer;
  }

  buildGuideLinesIntersectionCirclesVectorSource() {
    this.guideLinesIntersectionCirclesVectorSource = new VectorSource({
      features: this.mapModelSynchronizer.loadGuideLinesIntersectionCircles(
        this.guideLinesVectorSource.getFeatures(),
        this.roofPlanesVectorSource.getFeatures(),
      ),
    });
  }

  get guideLinesIntersectionCirclesVectorLayer() {
    if (!this.memoGuideLinesIntersectionCirclesVectorLayer) {
      this.memoGuideLinesIntersectionCirclesVectorLayer = new VectorLayer({
        source: this.guideLinesIntersectionCirclesVectorSource,
        style: (feature) => guideLinesIntersectionCirclesStyle(feature, this.map),
        zIndex: 25,
      });
      this.memoGuideLinesIntersectionCirclesVectorLayer.set("dataType", GUIDE_LINES_INTERSECTION_CIRCLE_DATA_TYPE);
    }
    return this.memoGuideLinesIntersectionCirclesVectorLayer;
  }

  get mapCenter() {
    return this.map.getView().getCenter();
  }

  get mapCenterLonLat() {
    return toLonLat(this.mapCenter);
  }

  get mapCenterLatLngObj() {
    const lonLat = this.mapCenterLonLat;
    return { lat: lonLat[1], lng: lonLat[0] };
  }

  allLayerFeatures(visibleLayersOnly = true) {
    if (!this.map) return [];

    const mapLayers = this.map
      .getLayers()
      .getArray()
      .filter((layer) => layer.getSource && typeof layer.getSource === "function");

    const filteredLayers = visibleLayersOnly ? mapLayers.filter((layer) => layer.getVisible()) : mapLayers;

    return filteredLayers.flatMap((layer) => {
      const source = layer.getSource();
      if (!source || !source.getFeatures || typeof source.getFeatures !== "function") {
        return [];
      }

      return source.getFeatures();
    });
  }

  get mapBaseResolution() {
    return this.map.getView().getResolution();
  }

  get mapLocalResolution() {
    return getPointResolution(this.mapProjection, this.mapBaseResolution, this.mapCenter);
  }

  get mapProjection() {
    return this.map.getView().getProjection();
  }

  addBuildingImagePlacementToMap(placement) {
    const feature = this.mapModelSynchronizer.createBuildingImagePlacementFeature(placement);

    // Adjust rotation when on viewer page to account for difference between editor and viewer rotations
    if (this.controller.isViewerPage) {
      const { projectSite } = this.project;
      const rotationDifference = projectSite.effectiveViewerRotation - projectSite.effectiveEditorRotation;
      const currentImageRotation = feature.get("imageRotation") || 0;
      feature.set("imageRotation", currentImageRotation + rotationDifference);
    }

    const layer = new VectorLayer({
      source: new VectorSource({
        features: [feature],
      }),
      // This allows the building image placement to be rendered at a higher resolution where
      // the center of the feature is out of map view.  By default this results in the image
      // disappearing from view.  This increases the render buffer to 5000 pixels, allowing the
      // user to zoom in closely on an edge of a building image.
      renderBuffer: 5000,
    });
    layer.set("dataType", BUILDING_IMAGE_PLACEMENT_DATA_TYPE);
    layer.set("placementUuid", feature.get("uuid"));

    this.map.addLayer(layer);

    this.buildingImagePlacementLayers.push(layer);
    this.buildingImagePlacementFeatures.push(feature);

    return feature;
  }

  removeBuildingImagePlacementFromMap(uuid) {
    this.buildingImagePlacementFeatures = this.buildingImagePlacementFeatures.filter((f) => f.get("uuid") !== uuid);
    const layer = this.buildingImagePlacementLayers.find((layer) => {
      return layer.get("placementUuid") === uuid;
    });
    this.buildingImagePlacementLayers = this.buildingImagePlacementLayers.filter((l) => l !== layer);
    this.map.removeLayer(layer);
  }

  addBuildingImagePlacementLayers() {
    this.project.notDeletedBuildingImagePlacements.forEach((placement) => {
      this.addBuildingImagePlacementToMap(placement);
    });
  }

  rotateBuildingImagePlacementsAfterMapRotation(startAngle, endAngle) {
    const rotationDelta = endAngle - startAngle;
    this.buildingImagePlacementFeatures.forEach((feature) => {
      const currentImageRotation = feature.get("imageRotation") || 0;
      feature.set("imageRotation", currentImageRotation + rotationDelta);
      feature.changed();

      this.mapModelSynchronizer.saveBuildingImagePlacementCoordinatesAndRotationToModel(feature);
    });
  }
}
