import MapBaseController from "./map_base_controller";

import SetbacksMapModelSynchronizer from "../../../../pr/map/map-model-synchronizers/setbacks";
import SetbacksMapManager from "../../../../pr/map/map-managers/setbacks";

import SelectInteractionManager from "../../../../pr/map/interaction-managers/select/setbacks";
import ModifyInteractionManager from "../../../../pr/map/interaction-managers/modify/setbacks";
import SnapInteractionManager from "../../../../pr/map/interaction-managers/snap/setbacks";
import MeasureInteractionManager from "../../../../da/map/interaction-managers/measure/base";
import TranslateInteractionManager from "../../../../pr/map/interaction-managers/translate/setbacks";

import {
  EDITOR_MODE_SELECT,
  EDITOR_MODE_MEASURE,
  SELECT_TYPE_MEASURES,
  SELECT_TYPE_SETBACK,
} from "../../../../da/layout-editor/helpers/toolbar-constants";
import * as toolbarSelectGroup from "../../../../da/layout-editor/helpers/toolbar-select-group";
import * as toolbarBtn from "../../../../da/layout-editor/helpers/toolbar-btns";
import { syncRoofSectionCartesianPoints } from "../../../../bx/map/modification-helpers/roof-section";
import { getSnapshot } from "mobx-state-tree";
import SegmentsLegalityChecker from "../../../../pr/map/legality-checkers/segments";
import { checkIfFeatureHasPointsInsideFeatureIncludingInteriorPoint } from "../../../../da/map/legality-checkers/polygon-helpers";
import { applyDomUpdatesFromResponse } from "../../../../helpers/api-dom-helpers";
import { reRenderSegment } from "../../../../pr/map/modification-helpers/segments/helpers";

export default class MapSetbacksController extends MapBaseController {
  static targets = [
    "map",
    "compass",
    "selectSetbackBtn",
    "selectMeasureBtn",
    "selectTypeMenuBtn",
    "selectTypeMenu",
    "resetDefaultSetbackBtn",
    "zeroSetbackBtn",
    "statusItemZoom",
    "statusItemRotation",
  ];

  connect() {
    super.connect();

    this.btnGroups = {
      select: [this.selectTypeMenuBtnTarget, this.selectSetbackBtnTarget, this.selectMeasureBtnTarget],
    };

    this.focusedRoofPlaneUuid = this.element.dataset.focusedRoofPlaneUuid;
    this.focusedRoofPlane = this.project.getRoofPlane(this.focusedRoofPlaneUuid);
    this.pageRoofPlanes = [this.focusedRoofPlane];
    this.slopeAdjustedMeasures = true;

    this.editorMode = toolbarSelectGroup.getState("prSetbackEditorMode", EDITOR_MODE_SELECT);
    this.selectType = toolbarSelectGroup.getState("prSetbackSelectType", SELECT_TYPE_SETBACK);

    this.mapSpecificConnect();
    this.setupMapInteractionManagers();
    this.initializeEditorMode();

    this.populateStatusbar();

    document.addEventListener("keydown", this.handleKeydown);

    const hasNewlyGeneratedRoofSections = this.focusedRoofPlane.roofSections.some((rs) => !rs.id);
    setTimeout(() => {
      this.alignViewToFeature(this.mapModelSynchronizer.setbackFeature, { markClean: !hasNewlyGeneratedRoofSections });
      this.mapManager.rotateTo(this.focusedRoofPlane.eaveRotationAngleRadians);
    }, 200);
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener("keydown", this.handleKeydown);
  }

  handleKeydown = (event) => {
    if (event.key === "Backspace") {
      if (event.target.tagName !== "INPUT") {
        event.preventDefault();
        this.removeSelectedFeatures(event);
      }
    } else if (event.key === "Escape") {
      this.measureInteractionManager.finish();
    }
  };

  get setbackIsSelected() {
    return this.selectInteractionManager.selectedFeatures.some((f) => f.get("dataType") === "Setback");
  }

  removeSelectedFeatures(event) {
    if (this.setbackIsSelected) return;

    this.selectInteractionManager.removeSelectedFeatures(event);
  }

  mapSpecificConnect() {
    this.mapModelSynchronizer = new SetbacksMapModelSynchronizer(this);

    this.mapManager = new SetbacksMapManager(this);
    this.mapManager.add();
    this.mapManager.buildNewDefaultRoofSectionsAndSetbacks();

    this.#hideResetDefaultSetbackBtnIfLegacyRoofSections();
  }

  setupMapInteractionManagers() {
    this.selectInteractionManager = new SelectInteractionManager(this, this.selectType);

    this.measureInteractionManager = new MeasureInteractionManager(this);

    this.snapInteractionManager = new SnapInteractionManager(this);
    this.snapInteractionManager.add();

    this.modifyInteractionManager = new ModifyInteractionManager(this);
    this.translateInteractionManager = new TranslateInteractionManager(this);
  }

  selectSetbackFeature() {
    const selectInteraction = this.selectInteractionManager.currentSelectInteraction;
    if (!selectInteraction || this.selectType !== SELECT_TYPE_SETBACK) return;

    const selectCollection = selectInteraction.getFeatures();

    const setbackFeatures = this.mapManager.setbacksVectorSource.getFeatures();
    setbackFeatures.forEach((sf) => selectCollection.push(sf));

    this.modifyInteractionManager.add();
  }

  initializeEditorMode() {
    switch (this.editorMode) {
      case EDITOR_MODE_MEASURE:
        this.startMeasureMode();
        break;
      case EDITOR_MODE_SELECT:
        this.startSelectMode();
        break;
      default:
        this.startSelectMode();
    }
  }

  startSelectMode(_event) {
    this.setEnabledForBtnGroup("select", true);
    this.selectInteractionManager.add();
    this.measureInteractionManager.remove();
    if (this.selectType === SELECT_TYPE_SETBACK) {
      this.selectSetbackFeature();
    }
    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  startMeasureMode(_event) {
    this.setEnabledForBtnGroup("select", false);
    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();

    this.selectInteractionManager.remove();
    this.measureInteractionManager.add();

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  setSelectToSetback(event) {
    if (event && toolbarBtn.isDisabled(event.currentTarget)) return;
    if (!event) this.selectTypeMenuTarget.selectGroupController.selectOption("setback");

    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();

    this.selectType = SELECT_TYPE_SETBACK;
    this.selectInteractionManager.setSelectType(this.selectType);
    this.selectSetbackFeature();
  }

  setSelectToMeasure(event) {
    if (event && toolbarBtn.isDisabled(event.currentTarget)) return;
    if (!event) this.selectTypeMenuTarget.selectGroupController.selectOption("measures");

    this.modifyInteractionManager.remove();
    this.translateInteractionManager.remove();

    this.selectType = SELECT_TYPE_MEASURES;
    this.selectInteractionManager.setSelectType(this.selectType);
  }

  // Called by both Stimulus event handler and saveAndThenRedirect triggered by auto save controller
  save(event, onSuccess = () => {}) {
    if (toolbarBtn.isDisabled(event.currentTarget)) return;

    this.selectInteractionManager.deselectSelected();
    toolbarBtn.showSpinner(this.saveBtnTarget);

    const currentPageCallback = (json) => {
      applyDomUpdatesFromResponse(json);
      this.markClean();

      if (onSuccess) onSuccess();

      this.focusedRoofPlane.roofSections.forEach((roofSection) => {
        roofSection.segments.forEach((segment) => {
          reRenderSegment(this, segment);
        });
      });

      setTimeout(() => {
        toolbarBtn.hideSpinner(this.saveBtnTarget);
      }, 500);
    };

    this.project.save({
      path: this.savePath,
      onSuccess: currentPageCallback,
      onValidationError: this.onValidationError,
      onServerError: this.onServerError,
      includeProjectSite: false,
      includeZonesAndModulePositions: true,
      includeUploadedBuildingImages: false,
    });

    if (this.selectType === SELECT_TYPE_SETBACK) {
      this.selectSetbackFeature();
    }
  }

  resetDefaultSetback(event) {
    this.selectInteractionManager.removeSelectedFeatures(event);
    this.mapManager.setbacksVectorSource.clear();

    const originLonLat = this.focusedRoofPlane.project.detail.originLatLng.toLonLat;

    const existingRoofSections = this.focusedRoofPlane.roofSections.filter((rs) => !rs.deleted);
    const segments = existingRoofSections.flatMap((rs) => rs.segments);
    existingRoofSections.forEach((rs) => {
      rs.detachSegments();
    });

    this.mapManager.roofSectionsBuilder.rebuildRoofSectionsForRoofPlane(this.focusedRoofPlane);

    const activeRoofSections = this.focusedRoofPlane.roofSections.filter((rs) => !rs.deleted);

    if (activeRoofSections.length === 1) {
      segments.forEach((segment) => {
        activeRoofSections[0].addSegment(segment);
      });
      syncRoofSectionCartesianPoints(originLonLat, activeRoofSections[0]);
    } else {
      const segmentFeatures = this.mapManager.segmentsVectorSource.getFeatures();
      activeRoofSections.forEach((rs) => {
        const rsFeature = this.mapModelSynchronizer.getFeatureForRoofSection(rs);
        segmentFeatures.forEach((sf) => {
          const segmentHasPointInsideRoofSection = checkIfFeatureHasPointsInsideFeatureIncludingInteriorPoint(
            sf,
            rsFeature,
          );
          if (segmentHasPointInsideRoofSection) {
            const segment = sf.get("model");
            rs.addExistingSegment(segment);
          }
        });
        syncRoofSectionCartesianPoints(originLonLat, rs);
      });
    }

    const setbackFeatures = this.mapModelSynchronizer.loadSetbacks();
    setbackFeatures.forEach((setbackFeature) => {
      this.mapManager.setbacksVectorSource.addFeature(setbackFeature);
    });

    this.mapManager.buildRoofSectionsVectorSource();

    const segmentsLegalityChecker = new SegmentsLegalityChecker(this);
    segmentsLegalityChecker.check();
    this.showEncroachingWarningMessage(segmentsLegalityChecker.encroaching);

    if (this.selectType === SELECT_TYPE_SETBACK) {
      this.selectSetbackFeature();
    }

    this.focusedRoofPlane.resequenceRoofSectionsIdentifiers();
    this.#showSetbackGuides();
    this.markDirty();
  }

  #hideResetDefaultSetbackBtnIfLegacyRoofSections() {
    // The reset default setback feature relies on the stored default lat lng points for roof sections.
    // If we have roof sections created before we rolled out this feature, hide this button.
    const hasLegacyRoofSections = this.focusedRoofPlane.roofSections.some((rs) => {
      return rs.id && rs.defaultLatLngPoints.length === 0;
    });
    if (!hasLegacyRoofSections) return;

    const btnContainer = this.resetDefaultSetbackBtnTarget.closest(".ol-map__btn__group");
    btnContainer.style.display = "none";
  }

  zeroSetback(event) {
    this.selectInteractionManager.removeSelectedFeatures(event);
    this.mapManager.setbacksVectorSource.clear();

    const originLonLat = this.focusedRoofPlane.project.detail.originLatLng.toLonLat;

    const existingRoofSections = this.focusedRoofPlane.roofSections.filter((rs) => !rs.deleted);

    const firstRoofSection = existingRoofSections[0];
    firstRoofSection.setLatLngPoints(getSnapshot(this.focusedRoofPlane.latLngPoints));
    syncRoofSectionCartesianPoints(originLonLat, firstRoofSection);

    existingRoofSections.forEach((rs, i) => {
      if (i === 0) return;

      rs.reassignSegments(firstRoofSection);

      if (rs.id === undefined) {
        this.focusedRoofPlane.destroyRoofSection(rs);
      } else {
        rs.flagDeleted();
      }
    });

    this.mapManager.roofSectionsFeatures.forEach((rsf) => {
      this.mapManager.roofSectionsVectorSource.removeFeature(rsf);
    });

    const setbackFeatures = this.mapModelSynchronizer.loadSetbacks();
    setbackFeatures.forEach((setbackFeature) => {
      this.mapManager.setbacksVectorSource.addFeature(setbackFeature);
    });

    this.mapManager.buildRoofSectionsVectorSource();

    const segmentsLegalityChecker = new SegmentsLegalityChecker(this);
    segmentsLegalityChecker.check();
    this.showEncroachingWarningMessage(segmentsLegalityChecker.encroaching);

    if (this.selectType === SELECT_TYPE_SETBACK) {
      this.selectSetbackFeature();
    }

    this.focusedRoofPlane.resequenceRoofSectionsIdentifiers();
    this.markDirty();
    this.#hideSetbackGuides();
  }

  #hideSetbackGuides() {
    this.#setbackGuidesFormController.hideAll();
  }

  #showSetbackGuides() {
    this.#setbackGuidesFormController.showAll();
  }

  get #setbackGuidesFormController() {
    return document.querySelector("[data-controller='pr--image-based--layout-editor--setback-guides-form").controller;
  }
}
