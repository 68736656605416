import { Controller } from "@hotwired/stimulus";

import LayoutEditorSettings, {
  SETTINGS,
  SETTINGS_EVENT,
  SHOW_SHAPE_GUIDE_LINES_OPTIONS,
} from "../../../da/layout-editor/settings";
import * as animate from "../../../helpers/animate";
import { elementInfo, isVisible, pointOverlapsElement } from "../../../helpers/app";
import * as toolbarBtn from "../../../da/layout-editor/helpers/toolbar-btns";

export default class extends Controller {
  static targets = [
    "menu",
    "button",
    "showShapeGuideLinesSelect",
    "showShapeGuideLinesForRoofPlanesCheckbox",
    "showShapeGuideLinesForRoofSectionsCheckbox",
    "showShapeGuideLinesForObstructionsCheckbox",
    "showShapeGuideLinesCheckboxesContainer",
  ];

  settings = new LayoutEditorSettings();

  connect() {
    this.#initializeGuideLinesSelect();
    this.#initializeCheckboxes();
    this.#toggleCheckboxPillsVisibility();
    document.body.addEventListener("click", this.#handleBodyClick.bind(this));
    window.addEventListener("resize", this.#handleResize.bind(this));
  }

  disconnect() {
    document.body.removeEventListener("click", this.#handleBodyClick.bind(this));
    window.removeEventListener("resize", this.#handleResize.bind(this));
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();

    if (isVisible(this.menuTarget)) {
      this.#hideMenu();
    } else {
      this.#positionMenu();
      this.#showMenu();
    }
  }

  hide(event) {
    event.preventDefault();
    this.#hideMenu();
  }

  handleShowShapeGuideLinesChange(event) {
    const value = event.target.value;
    const change = {
      setting: SETTINGS.SHOW_SHAPE_GUIDE_LINES,
      from: this.settings.showShapeGuideLines,
      to: value,
    };
    this.settings.showShapeGuideLines = value;
    this.#dispatchSettingChangedEvent(change);
    this.#toggleCheckboxPillsVisibility();
  }

  handleShowShapeGuideLinesForRoofPlanesChange(event) {
    const value = event.target.checked;
    const change = {
      setting: SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_PLANES,
      from: this.settings.showShapeGuideLinesForRoofPlanes,
      to: value,
    };
    this.settings.showShapeGuideLinesForRoofPlanes = value;
    this.#dispatchSettingChangedEvent(change);
  }

  handleShowShapeGuideLinesForRoofSectionsChange(event) {
    const value = event.target.checked;
    const change = {
      setting: SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_ROOF_SECTIONS,
      from: this.settings.showShapeGuideLinesForRoofSections,
      to: value,
    };
    this.settings.showShapeGuideLinesForRoofSections = value;
    this.#dispatchSettingChangedEvent(change);
  }

  handleShowShapeGuideLinesForObstructionsChange(event) {
    const value = event.target.checked;
    const change = {
      setting: SETTINGS.SHOW_SHAPE_GUIDE_LINES_FOR_OBSTRUCTIONS,
      from: this.settings.showShapeGuideLinesForObstructions,
      to: value,
    };
    this.settings.showShapeGuideLinesForObstructions = value;
    this.#dispatchSettingChangedEvent(change);
  }

  #hideMenu() {
    animate.hide(this.menuTarget, { duration: 150 });
    toolbarBtn.deactivate(this.buttonTarget);
  }

  #showMenu() {
    animate.show(this.menuTarget, { duration: 200 });
    toolbarBtn.activate(this.buttonTarget);
  }

  #toggleCheckboxPillsVisibility() {
    const showShapeGuideLines = this.settings.showShapeGuideLines;
    if (showShapeGuideLines === SHOW_SHAPE_GUIDE_LINES_OPTIONS.NEVER) {
      if (isVisible(this.showShapeGuideLinesCheckboxesContainerTarget)) {
        animate.hide(this.showShapeGuideLinesCheckboxesContainerTarget, { duration: 200 });
      }
    } else {
      if (!isVisible(this.showShapeGuideLinesCheckboxesContainerTarget)) {
        animate.show(this.showShapeGuideLinesCheckboxesContainerTarget, {
          duration: 250,
          display: "flex",
        });
      }
    }
  }

  #handleBodyClick = (event) => {
    if (!isVisible(this.menuTarget)) return;

    const { clientX: x, clientY: y } = event;

    const clickedOnMenu = pointOverlapsElement({ x, y, element: this.menuTarget });
    const clickedOnButton = pointOverlapsElement({ x, y, element: this.buttonTarget });

    if (!clickedOnMenu && !clickedOnButton) this.hide(event);
  };

  #positionMenu() {
    const buttonInfo = elementInfo(this.buttonTarget);
    const containerInfo = elementInfo(this.element);

    const containerStyle = window.getComputedStyle(this.element);
    const borderRightWidth = parseInt(containerStyle.borderRightWidth, 10) || 0;
    const borderTopWidth = parseInt(containerStyle.borderTopWidth, 10) || 0;

    const pixelOffset = 10; // Same padding as between toolbar buttons
    const minLeftPadding = 15; // Same padding as other vertical toolbar buttons
    const topOffset = buttonInfo.bottom - containerInfo.top + pixelOffset - borderTopWidth;

    const menuWidth = this.#getMenuWidthOffScreen();

    const expectedLeftPosition = buttonInfo.right - menuWidth;

    this.menuTarget.style.top = `${topOffset}px`;

    const hasEnoughSpaceOnLeft = expectedLeftPosition > containerInfo.left + minLeftPadding;
    if (!hasEnoughSpaceOnLeft) {
      this.menuTarget.style.left = `${minLeftPadding}px`;
      this.menuTarget.style.right = "auto";
    } else {
      const rightOffset = containerInfo.right - buttonInfo.right - borderRightWidth;
      this.menuTarget.style.right = `${rightOffset}px`;
      this.menuTarget.style.left = "auto";
    }
  }

  #dispatchSettingChangedEvent(change) {
    const customEvent = new CustomEvent(SETTINGS_EVENT.CHANGED, {
      bubbles: true,
      detail: { change },
    });
    document.dispatchEvent(customEvent);
  }

  #initializeGuideLinesSelect() {
    const savedValue = this.settings.showShapeGuideLines;
    if (this.hasShowShapeGuideLinesSelectTarget) {
      this.showShapeGuideLinesSelectTarget.value = savedValue;
    }
  }

  #initializeCheckboxes() {
    if (this.hasShowShapeGuideLinesForRoofPlanesCheckboxTarget) {
      this.showShapeGuideLinesForRoofPlanesCheckboxTarget.checked = this.settings.showShapeGuideLinesForRoofPlanes;
    }

    if (this.hasShowShapeGuideLinesForRoofSectionsCheckboxTarget) {
      this.showShapeGuideLinesForRoofSectionsCheckboxTarget.checked = this.settings.showShapeGuideLinesForRoofSections;
    }

    if (this.hasShowShapeGuideLinesForObstructionsCheckboxTarget) {
      this.showShapeGuideLinesForObstructionsCheckboxTarget.checked = this.settings.showShapeGuideLinesForObstructions;
    }
  }

  #handleResize = () => {
    if (isVisible(this.menuTarget)) {
      this.#hideMenu();
    }
  };

  // The width of the menu is not fixed, so we need to measure it. However, we need
  // to make the menu visible to do this, and we don't want to show the menu yet, so
  // we position it off screen, show it, measure it, and then hide it again.
  #getMenuWidthOffScreen() {
    this.menuTarget.style.left = "-9999px";
    this.menuTarget.style.right = "auto";
    this.menuTarget.style.display = "block";

    const menuWidth = this.menuTarget.offsetWidth;

    this.menuTarget.style.display = "none";

    return menuWidth;
  }
}
