import BufferBuilder from "./buffering/buffer-builder";
import { linkFeatureToModel } from "./modification-helpers/base";
import RoofSectionLegalityChecker from "./legality-checkers/roof-section";

import { featureEdgesIntersect, checkIfFeatureHasPointsInsideFeature } from "./legality-checkers/polygon-helpers";

export default class DefaultRoofSectionsBuilder extends BufferBuilder {
  constructor(controller, map, roofPlaneFeatures, roofPlaneWithPriorLayout) {
    super();

    this.controller = controller;
    this.map = map;
    this.roofPlaneFeatures = roofPlaneFeatures;
    // only used by BX will be undefined when called from PR or BX contexts other than
    // modifying an existing roof plane
    this.roofPlaneWithPriorLayout = roofPlaneWithPriorLayout;

    this.project = controller.project;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;

    this.buildDefaultRoofSectionsForNewRoofPlanes();
  }

  buildDefaultRoofSectionsForNewRoofPlanes() {
    this.project.roofPlanes.forEach((roofPlane) => {
      if (roofPlane.deleted) return;
      if (roofPlane.hasDefaultRoofSections) return;
      if (isNaN(roofPlane.setback) || roofPlane.setback < 0.0) return;
      if (!roofPlane.display) return;

      this.buildDefaultRoofSectionsForRoofPlane(roofPlane);
      this.postBuildDaSpecificActions(roofPlane);
      this.controller.markDirty();
    });
  }

  rebuildRoofSectionsForRoofPlane(roofPlane) {
    this.controller.mapManager.roofSectionsFeatures.forEach((rsf) => {
      this.controller.mapManager.roofSectionsVectorSource.removeFeature(rsf);
    });
    roofPlane.clearRoofSections();
    this.buildDefaultRoofSectionsForRoofPlane(roofPlane);
  }

  // override in sub-class if you have anything to do after building the defaults for a roofPlane
  postBuildDaSpecificActions(roofPlane) {}

  buildDefaultRoofSectionsForRoofPlane(roofPlane) {
    const matchingRoofPlaneFeature = this.roofPlaneFeatures.find((feature) => {
      return feature.get("uuid") === roofPlane.uuid;
    });

    if (!matchingRoofPlaneFeature) {
      debugger;
    }

    const inset = -1 * roofPlane.setback;

    const translatedFeaturesCollection = this.translateOpenLayersFeaturesToTurf(matchingRoofPlaneFeature);
    const bufferedFeatures = this.buildBufferFeatures(translatedFeaturesCollection, inset);
    this.translateTurfFeaturesToModels(bufferedFeatures);

    // check the custom roof sections for the roof plane incase they encroach into the new setback
    new RoofSectionLegalityChecker(
      matchingRoofPlaneFeature,
      this.controller.mapManager.roofSectionsFeatures,
      this.mapModelSynchronizer,
    ).markIllegalPolygons();
  }

  buildModelsFromTurfFeatures(featuresFromBuffering) {
    return featuresFromBuffering.flatMap((feature) => {
      const roofPlaneUuid = feature.get("uuid");
      const roofPlane = this.project.getRoofPlane(roofPlaneUuid);

      const polygonFeatures = this.mapMultiPolygon(feature);

      this.doNotTransferPriorLayoutIfRoofPlaneNowHasMultipleRoofSections(roofPlane, polygonFeatures);

      polygonFeatures.forEach((polygonFeature) => {
        const activityForNewDefaultRoofSection = this.doesNotOverlapAnyCustomRoofSections(roofPlane, polygonFeature);

        const roofSection = roofPlane.addRoofSection({ custom: false, active: activityForNewDefaultRoofSection });

        const latLngPoints = this.mapModelSynchronizer.latLngPointsFromFeature(polygonFeature);
        roofSection.setLatLngPoints(latLngPoints);
        if (roofSection.setDefaultLatLngPoints) roofSection.setDefaultLatLngPoints(latLngPoints);

        if (activityForNewDefaultRoofSection) {
          this.addRoofSectionPolygonFeatureToMap(polygonFeature, roofSection);
        }
      });
    });
  }

  // If the roof plane now has multiple roof sections we don't know which one the old layout should go into
  doNotTransferPriorLayoutIfRoofPlaneNowHasMultipleRoofSections(roofPlane, polygonFeatures) {
    if (!this.roofPlaneWithPriorLayout) return;
    if (this.roofPlaneWithPriorLayout.uuid !== roofPlane.uuid) return;
    if (polygonFeatures.length < 2) return;

    this.roofPlaneWithPriorLayout = undefined;
  }

  addRoofSectionPolygonFeatureToMap(polygonFeature, roofSection) {
    linkFeatureToModel(polygonFeature, roofSection);
    this.controller.mapManager.roofSectionsVectorSource.addFeature(polygonFeature);
  }

  doesNotOverlapAnyCustomRoofSections(roofPlane, polygonFeature) {
    if (!roofPlane.hasCustomRoofSections) return true;

    for (let i = 0; i < roofPlane.customRoofSections.length; i++) {
      const roofSection = roofPlane.customRoofSections[i];
      const roofSectionFeature = this.mapModelSynchronizer.getFeatureForRoofSection(roofSection);

      if (this.newDefaultOverlapsExistingFeature(polygonFeature, roofSectionFeature)) return false;
    }

    return true;
  }

  newDefaultOverlapsExistingFeature(polygonFeature, roofSectionFeature) {
    const edgeIntersection = featureEdgesIntersect(polygonFeature, roofSectionFeature);
    const pointsInside = checkIfFeatureHasPointsInsideFeature(polygonFeature, roofSectionFeature);

    return edgeIntersection || pointsInside;
  }
}
