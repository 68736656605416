import { LineString, Polygon } from "ol/geom";
import { logger } from "../../../../helpers/app";

// This function constrains a line being drawn to horizontal or vertical orientation when the user holds
// the "c" key. The constraint is relative to the browser window - after drawing, the line becomes fixed
// map coordinates, so if the map is rotated the line will rotate with it.
//
// To use this functionality in your interaction manager class:
//
// 1. Import both the function and the mixin:
//    import { constraintGeometryFunction, withConstraintMixin } from "../helpers/constraint";
//
// 2. Add the mixin to your class after the class definition:
//    Object.assign(YourClass.prototype, withConstraintMixin);
//
// 3. Initialize the constraint functionality in your constructor:
//    constructor(controller) {
//      // ... your initialization code ...
//      this.initConstraint();
//    }
//
// 4. Add the geometry function to your Draw interaction:
//    new Draw({
//      // ... other options ...
//      geometryFunction: (coordinates, geometry) =>
//        constraintGeometryFunction(coordinates, geometry, this.map, this.constrainPressed),
//    });
//
// 5. Attach/detach listeners in your add/remove methods:
//    add() {
//      this.attachConstraintListeners();
//      // ... rest of add logic ...
//    }
//
//    remove() {
//      this.detachConstraintListeners();
//      // ... rest of remove logic ...
//    }
//
// See app/javascript/da/map/interaction-managers/measure/base.js for a complete example.

const DEBUG_LOGGING = false;
const SUPPORTED_GEOMETRY_TYPES = ["LineString", "Polygon"];

export const constraintGeometryFunction = (coordinates, geometry, map, constrainPressed, geometryType) => {
  if (!SUPPORTED_GEOMETRY_TYPES.includes(geometryType)) {
    throw new Error(`Unsupported geometry type passed to constraintGeometryFunction: ${geometryType}`);
  }

  if (DEBUG_LOGGING) {
    logger("[constraint] constraintGeometryFunction", {
      geometryType,
      coordinates,
      constrainPressed,
    });
  }

  if (!geometry) {
    if (geometryType === "LineString") geometry = new LineString([]);
    if (geometryType === "Polygon") geometry = new Polygon([coordinates]);
  }

  if (!constrainPressed) {
    geometry.setCoordinates(coordinates);
    return geometry;
  }

  let newCoords;

  if (geometryType === "LineString") {
    if (DEBUG_LOGGING) logger("[constraint] constraining line string");

    newCoords = constrainLineString(coordinates, map);
  }

  if (geometryType === "Polygon") {
    if (DEBUG_LOGGING) logger("[constraint] constraining polygon");

    newCoords = constrainPolygon(coordinates, map);
  }

  geometry.setCoordinates(newCoords);
  return geometry;
};

// Constrain the last segment of a line string
function constrainLineString(coordinates, map) {
  if (coordinates.length > 1) {
    const lastIndex = coordinates.length - 1;
    const secondLastIndex = lastIndex - 1;
    const startCoord = coordinates[secondLastIndex];
    const endCoord = coordinates[lastIndex];

    const lockedEnd = applyHorizontalVerticalLock(startCoord, endCoord, map);
    coordinates[lastIndex] = lockedEnd;
  }
  return coordinates;
}

// Constrain the last segment of a polygon
function constrainPolygon(coordinates, map) {
  if (coordinates.length > 0) {
    const ring = coordinates[0];
    if (ring.length > 1) {
      const lastIndex = ring.length - 1;
      const secondLastIndex = lastIndex - 1;

      const startCoord = ring[secondLastIndex];
      const endCoord = ring[lastIndex];
      const lockedEnd = applyHorizontalVerticalLock(startCoord, endCoord, map);
      ring[lastIndex] = lockedEnd;
    }
    coordinates[0] = ring;
  }
  return coordinates;
}

function applyHorizontalVerticalLock(startCoord, endCoord, map) {
  const startPixel = map.getPixelFromCoordinate(startCoord);
  const endPixel = map.getPixelFromCoordinate(endCoord);
  const dx = Math.abs(endPixel[0] - startPixel[0]);
  const dy = Math.abs(endPixel[1] - startPixel[1]);
  if (dx > dy) {
    endPixel[1] = startPixel[1]; // Lock horizontally
  } else {
    endPixel[0] = startPixel[0]; // Lock vertically
  }
  return map.getCoordinateFromPixel(endPixel);
}

export function isRingClosed(ring) {
  if (!ring || !ring.length) return false;
  const firstCoordinate = ring[0];
  const lastCoordinate = ring[ring.length - 1];
  return (
    firstCoordinate &&
    lastCoordinate &&
    firstCoordinate[0] === lastCoordinate[0] &&
    firstCoordinate[1] === lastCoordinate[1]
  );
}

export const withConstraintMixin = {
  initConstraint() {
    this.constrainPressed = false;
    this.constraintListenersAttached = false;

    this.handleConstraintKeyDown = this.handleConstraintKeyDown.bind(this);
    this.handleConstraintKeyUp = this.handleConstraintKeyUp.bind(this);

    if (DEBUG_LOGGING) logger("[constraint] initialized constraint");
  },

  attachConstraintListeners() {
    if (!this.constraintListenersAttached) {
      document.addEventListener("keydown", this.handleConstraintKeyDown);
      document.addEventListener("keyup", this.handleConstraintKeyUp);
      this.constraintListenersAttached = true;

      if (DEBUG_LOGGING) logger("[constraint] attached constraint listeners");
    }
  },

  detachConstraintListeners() {
    if (this.constraintListenersAttached) {
      document.removeEventListener("keydown", this.handleConstraintKeyDown);
      document.removeEventListener("keyup", this.handleConstraintKeyUp);
      this.constraintListenersAttached = false;

      if (DEBUG_LOGGING) logger("[constraint] detached constraint listeners");
    }
  },

  handleConstraintKeyDown(event) {
    if (!event || !event.key) return;

    if (event.key.toLowerCase() === "c") {
      if (DEBUG_LOGGING) logger("[constraint] pressed 'c', now constraining");

      this.constrainPressed = true;
    }
  },

  handleConstraintKeyUp(event) {
    if (!event || !event.key) return;

    if (event.key.toLowerCase() === "c") {
      if (DEBUG_LOGGING) logger("[constraint] released 'c', now unconstrained");

      this.constrainPressed = false;
    }
  },

  // Closing the polygon ring if not already closed. OpenLayers does this automatically
  // when not supplying a custom geometry function for the draw interaction. However,
  // if you supply a custom geometry function, like constraintGeometryFunction, you
  // are on your own for closing the ring. Unfortunately, you can't just do this step
  // in the geometry function itself because OpenLayers still does some cleanup after
  // the drawing is complete, which ends up removing the closing coordinate for some
  // reason. But, you can still do this step in the drawend event handler, which
  // happens after OpenLayers is finished with its work.
  constraintPolygonOnDrawEnd(event) {
    const feature = event.feature;
    if (!feature) return;

    const geom = feature.getGeometry();
    if (geom.getType() !== "Polygon") return;

    const coords = geom.getCoordinates();
    const ring = coords[0];

    if (ring && ring.length && !isRingClosed(ring)) {
      ring.push(ring[0]);
      geom.setCoordinates([ring]);
    }
  },
};
