import { Controller } from "@hotwired/stimulus";
import { confirmDialog } from "./helper";
import * as request from "@rails/request.js";
import { Form, submitWithTabId } from "../../../helpers/form";
import { Turbo } from "@hotwired/turbo-rails";

export default class ConfirmationController extends Controller {
  static values = {
    url: String,
    urlMethod: { type: String, default: "get" },
    responseKind: { type: String, default: "html" }, // can also be "js" or "turbo_stream"
    color: { type: String, default: "orange" },
    title: { type: String, default: "Confirmation" },
    message: { type: String, default: "Are you sure?" },
    useEventClickPosition: { type: Boolean, default: true },
  };

  static targets = ["form"];

  open(event) {
    event.preventDefault();

    const callback = (confirmed) => {
      if (!confirmed) return;

      const url = this.urlValue;
      const method = this.urlMethodValue;
      const tabId = window.sessionStorage.getItem("tabId");

      if (this.hasFormTarget) {
        if (this.responseKindValue === "turbo_stream") {
          let formData = new FormData(this.formTarget);
          formData.append("request_format", "turbo_stream");

          fetch(this.formTarget.action, {
            method: "POST",
            body: formData,
            headers: {
              Accept: "text/vnd.turbo-stream.html",
              "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
              "X-Tab-Id": tabId,
            },
            credentials: "same-origin",
          })
            .then((response) => response.text())
            .then((html) => {
              Turbo.renderStreamMessage(html);
            });
        } else if (this.responseKindValue === "js") {
          const body = new FormData(this.formTarget);

          fetch(this.formTarget.action, {
            method: "POST",
            body,
            headers: {
              Accept: "text/javascript",
              "X-Requested-With": "XMLHttpRequest",
              "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
              "X-Tab-Id": tabId,
            },
            credentials: "same-origin",
          })
            .then((response) => response.text())
            .then((text) => {
              window.eval(text);
            });
        } else {
          submitWithTabId(this.formTarget);
        }
      } else if (this.responseKindValue === "html") {
        Form[method](url);
      } else {
        request[method](url, { responseKind: this.responseKindValue, headers: { "X-Tab-Id": tabId } });
      }
    };

    const clickPosition = this.useEventClickPositionValue ? { x: event.pageX, y: event.pageY } : undefined;
    confirmDialog(this.messageValue, callback, {
      title: this.titleValue,
      confirmBtnColor: this.colorValue,
      headerColor: this.colorValue,
      showNearClickPosition: this.useEventClickPositionValue,
      clickPosition,
    });
  }
}
