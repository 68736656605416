import { hasSameSlope, linesAreIntersectingAndDontOverlap } from "../../../helpers/geometry";
import { pointInPolygon } from "../../../helpers/point-in-polygon";

export function featureEdgesIntersect(feature, otherFeature) {
  const coordinatesOfFeatureAsPoints = verticesFromFeatureToPoints(feature);
  const coordinatesOfOtherFeatureAsPoints = verticesFromFeatureToPoints(otherFeature);

  return polygonsAsPointListsIntersect(coordinatesOfFeatureAsPoints, coordinatesOfOtherFeatureAsPoints);
}

export function polygonsAsPointListsIntersect(coordinatesOfFeatureAsPoints, coordinatesOfOtherFeatureAsPoints) {
  for (let i = 0; i < coordinatesOfFeatureAsPoints.length - 1; i++) {
    const p1 = coordinatesOfFeatureAsPoints[i];
    const p2 = coordinatesOfFeatureAsPoints[i + 1];

    for (let j = 0; j < coordinatesOfOtherFeatureAsPoints.length - 1; j++) {
      const p3 = coordinatesOfOtherFeatureAsPoints[j];
      const p4 = coordinatesOfOtherFeatureAsPoints[j + 1];

      const parallel = hasSameSlope(p1, p2, p3, p4);
      if (!parallel && linesAreIntersectingAndDontOverlap(p1, p2, p3, p4)) {
        // console.log("Intersection found");
        // console.log("Feature", coordinatesOfFeatureAsPoints);
        // console.log("Other", coordinatesOfOtherFeatureAsPoints);

        // console.log(`Line 1 (${p1[0]}, ${p1[1]})-(${p2[0]}, ${p2[1]})`);
        // console.log(`Line 2 (${p3[0]}, ${p3[1]})-(${p4[0]}, ${p4[1]})`);
        return true;
      }
    }
  }

  return false;
}

export function markIllegalIfFeatureHasPointsInsideFeature(feature, otherFeature) {
  return checkAndMarkIllegalIfFeatureHasPointsInsideFeature(feature, otherFeature, true);
}

export function checkIfFeatureHasPointsInsideFeature(feature, otherFeature) {
  return checkAndMarkIllegalIfFeatureHasPointsInsideFeature(feature, otherFeature, false);
}

export function markIllegalIfFeatureHasPointsInsideFeatureIncludingInteriorPoint(feature, otherFeature) {
  const isIllegal = checkIfFeatureHasPointsInsideFeatureIncludingInteriorPoint(feature, otherFeature);
  if (isIllegal) feature.set("illegalShape", true);
  return isIllegal;
}

export function checkIfFeatureHasPointsInsideFeatureIncludingInteriorPoint(feature, otherFeature) {
  const hasInteriorPointInside = checkIfFeatureHasInteriorPointInsideFeature(feature, otherFeature);
  if (hasInteriorPointInside) return true;
  return checkIfFeatureHasPointsInsideFeature(feature, otherFeature);
}

export function checkIfFeatureHasInteriorPointInsideFeature(feature, otherFeature) {
  const internalPointCoordinate = feature.getGeometry().getInteriorPoint().getCoordinates();
  const otherFeatureCoordinates = verticesFromFeatureToPoints(otherFeature);
  const isInside = pointInPolygon(internalPointCoordinate, [otherFeatureCoordinates]);
  return isInside;
}

function checkAndMarkIllegalIfFeatureHasPointsInsideFeature(feature, otherFeature, markFeatures) {
  const featureVertices = verticesFromFeatureToPoints(feature);
  const featureVerticesPlusNearbyPoints = verticesPlusNearbyPointsFromFeature(feature);
  const otherFeatureVertices = verticesFromFeatureToPoints(otherFeature);
  const otherFeatureVerticesPlusNearbyPoints = verticesPlusNearbyPointsFromFeature(otherFeature);

  const check1 = checkPointSetAgainstPolygonVertices(
    feature,
    featureVerticesPlusNearbyPoints,
    otherFeatureVertices,
    markFeatures,
  );
  const check2 = checkPointSetAgainstPolygonVertices(
    otherFeature,
    otherFeatureVerticesPlusNearbyPoints,
    featureVertices,
    markFeatures,
  );

  return check1 || check2;
}

export function checkPointSetAgainstPolygonVertices(
  feature,
  featureVerticesPlusNearbyPoints,
  otherFeatureVertices,
  markFeatures,
) {
  // console.log("Calling checkPointSetAgainstPolygonVertices");
  return featureVerticesPlusNearbyPoints.some((vertex) => {
    const vertexInOtherFeature = pointInPolygon(vertex, [otherFeatureVertices]);
    if (vertexInOtherFeature) {
      // console.log("Checking if vertex:", vertex, "is inside", otherFeatureVertices);
      // console.log("Result", vertexInOtherFeature);
      if (markFeatures) {
        feature.set("illegalShape", true);
      }
      return true;
    }
    return false;
  });
}

export function verticesFromFeatureToPoints(feature) {
  const geometry = feature.getGeometry();
  const coordinates = geometry.getLinearRing(0).getCoordinates();

  return coordinates.map((c) => [c[0], c[1]]);
}

export function verticesPlusNearbyPointsFromFeature(feature) {
  const coordinates = verticesFromFeatureToPoints(feature);

  const result = [];

  for (let i = 0; i < coordinates.length - 1; i++) {
    const p1 = coordinates[i];
    const p2 = coordinates[i + 1];

    const vectorBetweenPoints = [p2[0] - p1[0], p2[1] - p1[1]];
    const scaledVectorBetweenPoints = [0.01 * vectorBetweenPoints[0], 0.01 * vectorBetweenPoints[1]];

    const nearbyP1 = [p1[0] + scaledVectorBetweenPoints[0], p1[1] + scaledVectorBetweenPoints[1]];
    const nearbyP2 = [p2[0] - scaledVectorBetweenPoints[0], p2[1] - scaledVectorBetweenPoints[1]];

    result.push(p1);
    result.push(nearbyP1);
    result.push(nearbyP2);
  }

  return result;
}
