import { clone, destroy } from "mobx-state-tree";

import MapBaseController from "./map_base_controller";
import SelectInteractionManager from "../../../bx/map/interaction-managers/select/edit-arrays";
import SnapInteractionManager from "../../../bx/map/interaction-managers/snap/edit-arrays";
import DragBoxInteractionManager from "../../../bx/map/edit-array/drag-box-interaction-manager";
import PaintSelectInteractionManager from "../../../bx/map/edit-array/paint-select-interaction-manager";
import ModifyInteractionManager from "../../../bx/map/interaction-managers/modify/edit-arrays";
import TranslateInteractionManager from "../../../da/map/interaction-managers/translate/base";
import MeasureInteractionManager from "../../../da/map/interaction-managers/measure/base";

import { applyDomUpdatesFromResponse } from "../../../helpers/api-dom-helpers";
import { applyOpenLayersUpdatesFromModels } from "../api_open_layers_helpers";
import { degreesToRadians } from "../../../helpers/geometry";
import { logger, humanizeWattage } from "../../../helpers/app";
import {
  addLayoutAndArrayForRoofSection,
  addLayoutForRoofSection,
} from "../../../bx/map/modification-helpers/roof-section";
import * as toolbarBtn from "../../../da/layout-editor/helpers/toolbar-btns";
import {
  EDITOR_MODE_PAINT,
  EDITOR_MODE_MEASURE,
  EDITOR_MODE_SELECT,
  PANEL_MODE_ADD,
  PANEL_MODE_REMOVE,
  PANEL_MODE_TOGGLE,
} from "../../../da/layout-editor/helpers/toolbar-constants";
import * as toolbarSelectGroup from "../../../da/layout-editor/helpers/toolbar-select-group";

import MarqueeMarker from "../../../da/map/marquee-marker";
import { fromLonLat } from "ol/proj";

export default class MapEditArrayController extends MapBaseController {
  allowNudgingWithArrowKeys = true;

  connect() {
    this.showBoundingBoxes = this.element.dataset.showBoundingBoxes === "true";

    super.connect();

    this.roofSectionUuid = this.element.dataset.roofSectionUuid;

    this.roofSection = this.project.getRoofSection(this.roofSectionUuid);
    this.rsFeature = this.mapModelSynchronizer.getFeatureForRoofSection(this.roofSection);

    this.mapManager.buildSetbacksVectorSource();
    this.mapManager.addRoofSectionsAndSetbacksVectorLayers();
    this.mapManager.addArraysVectorLayer();

    this.setupMapInteractionManagers();

    this.mapManager.rotateTo(this.negateAzimuthRotationRadians);

    this.initializeMode();

    document.addEventListener("keydown", this.handleKeydown);

    this.populateStatusbar();

    this.marqueeMarker = new MarqueeMarker();
    this.marqueeMarker.addTo(this.mapTarget);

    this.isUnpersistedZoomRotationPositionPage = true;
    setTimeout(() => {
      this.alignViewToSelectedRoofSection(true);
    }, 200);

    setTimeout(() => {
      this.addSnapshotToUndoQueue(false);
    }, 1000);
  }

  disconnect() {
    super.disconnect();

    document.removeEventListener("keydown", this.handleKeydown);
  }

  undo(event) {
    super.undo(event);
    this.afterUndoRedo();
  }

  redo(event) {
    super.redo(event);
    this.afterUndoRedo();
  }

  afterUndoRedo() {
    this.mapManager.rotateTo(this.negateAzimuthRotationRadians);
    // Allow rotation to finish
    setTimeout(() => {
      this.updateAzimuthFormWithValueFromProjectDetail();
      this.setZoomAndPositionFromProjectSite();
    }, 300);
  }

  updateAzimuthFormWithValueFromProjectDetail() {
    const azimuthForm = document.querySelector("[data-controller='bx--layout-editor--azimuth-management']");
    const azimuthFormController = azimuthForm.controller;

    const roofPlane = this.project.displayableRoofPlanes[0];
    const roofSection = roofPlane.roofSections[0];
    azimuthFormController.setAzimuthValue(roofSection.azimuth);
  }

  setZoomAndPositionFromProjectSite() {
    const { projectSite } = this.project;
    this.mapManager.map.getView().setZoom(projectSite.unpersistedZoom);

    const coordinate = fromLonLat([projectSite.unpersistedLng, projectSite.unpersistedLat]);
    this.mapManager.setCenter(coordinate);
  }

  zoomIn(event) {
    super.zoomIn(event);
    this.zoomInAndOut("zoomIn");
  }

  zoomOut(event) {
    super.zoomOut(event);
    this.zoomInAndOut("zoomOut");
  }

  zoomInAndOut(from) {
    // Need to wait for the zooming animation to finish
    setTimeout(() => {
      this.skipNextMapMoveEndEvent = true;
      this.mapModelSynchronizer.saveEditorLocation();
      this.populateStatusbar();
      this.mapManager.dispatchAfterMapFeaturesRendering({ calledFrom: `EditArrayController#${from}` });
    }, 300);
  }

  setupMapInteractionManagers() {
    this.selectInteractionManager = new SelectInteractionManager(this);
    this.selectInteractionManager.add();

    this.snapInteractionManager = new SnapInteractionManager(this);
    this.snapInteractionManager.add();

    this.dragBoxInteractionManager = new DragBoxInteractionManager(this);
    this.paintSelectInteractionManager = new PaintSelectInteractionManager(this);
    this.modifyInteractionManager = new ModifyInteractionManager(this);
    this.translateInteractionManager = new TranslateInteractionManager(this);
    this.measureInteractionManager = new MeasureInteractionManager(this);
  }

  initializeMode() {
    this.editorMode = toolbarSelectGroup.getState("bxArraysEditorMode", EDITOR_MODE_PAINT);
    this.panelMode = toolbarSelectGroup.getState("bxPanelModificationType", PANEL_MODE_ADD);

    this.initializeEditorMode();
  }

  initializeEditorMode() {
    switch (this.editorMode) {
      case EDITOR_MODE_PAINT:
        this.startPaintPanels();
        break;
      case EDITOR_MODE_MEASURE:
        this.startMeasureMode();
        break;
      case EDITOR_MODE_SELECT:
        this.startSelectMode();
        break;
      default:
        this.startPaintPanels();
    }
  }

  setMapSpecificStatusBarItems() {
    this.statusItemPanelsTarget.innerText = `${this.roofSection.panelsPresent} / ${this.project.panelsPresent}`;
    const section = humanizeWattage(this.roofSection.wattage);
    const total = humanizeWattage(this.project.wattage);
    this.statusItemWattageTarget.innerText = `${section} / ${total}`;
  }

  get negateAzimuthRotationRadians() {
    return degreesToRadians(180.0 - this.roofSection.azimuth);
  }

  alignViewToSelectedRoofSection = (shouldMarkClean) => {
    const view = this.mapManager.map.getView();
    const roofSectionGeometry = this.rsFeature.getGeometry();
    view.fit(roofSectionGeometry, { padding: [100, 30, 30, 30] });
    // Fit considers our original rotation, but may modify it. So we set things back to
    // the rotation we want to have the grid exactly up / down
    this.mapManager.rotateTo(this.negateAzimuthRotationRadians);
    this.mapModelSynchronizer.saveEditorLocation();

    if (!shouldMarkClean) return;

    setTimeout(() => {
      this.markClean();
    }, 50);
  };

  handleKeydown = (event) => {
    if (event.key === "Backspace") {
      if (event.target.tagName !== "INPUT") {
        event.preventDefault();
        this.selectInteractionManager.removeSelectedFeatures(event);
      }
    } else if (event.key === "Escape") {
      this.measureInteractionManager.finish();
    }

    if (!this.allowNudgingWithArrowKeys) return;

    if (event.key === "ArrowUp") this.nudgeUp(event, "up");
    if (event.key === "ArrowRight") this.nudgeRight(event, "right");
    if (event.key === "ArrowDown") this.nudgeDown(event, "down");
    if (event.key === "ArrowLeft") this.nudgeLeft(event, "left");
  };

  // Called by both Stimulus event handler and saveAndThenRedirect triggered by auto save controller
  save(_event, onSuccess = () => {}) {
    toolbarBtn.showSpinner(this.saveBtnTarget);
    this.project.save({
      path: this.savePath,
      onSuccess: (json) => {
        if (json) applyDomUpdatesFromResponse(json);
        applyOpenLayersUpdatesFromModels(this.project, this.mapModelSynchronizer, this.mapManager);
        this.markClean();
        onSuccess();
        setTimeout(() => {
          toolbarBtn.hideSpinner(this.saveBtnTarget);
        }, 500);
      },
      onValidationError: this.onValidationError,
      onServerError: this.onServerError,
      includeProjectSite: false,
      includeUploadedBuildingImages: false,
    });
  }

  nudgeUp(event) {
    this.mapManager.nudgeAlongAzimuth(this.roofSection, this.rsFeature, -1 * this.nudgeAmount(event));
    this.markDirty();
  }

  nudgeDown(event) {
    this.mapManager.nudgeAlongAzimuth(this.roofSection, this.rsFeature, this.nudgeAmount(event));
    this.markDirty();
  }

  nudgeRight(event) {
    this.mapManager.nudgePerpendicularToAzimuth(this.roofSection, this.rsFeature, this.nudgeAmount(event));
    this.markDirty();
  }

  nudgeLeft(event) {
    this.mapManager.nudgePerpendicularToAzimuth(this.roofSection, this.rsFeature, -1 * this.nudgeAmount(event));
    this.markDirty();
  }

  nudgeAmount(event) {
    let amount = 6;
    if (event.shiftKey) amount = 24;
    if (event.altKey) amount = 1;
    if (event.shiftKey && event.altKey) amount = 0.1;

    return amount;
  }

  center(_event) {
    this.mapManager.nudgeReset(this.roofSection);
    this.markDirty();
  }

  get isEditorModePaintPanels() {
    return this.editorMode === EDITOR_MODE_PAINT;
  }

  get isEditorModeMeasure() {
    return this.editorMode === EDITOR_MODE_MEASURE;
  }

  get isEditorModeSelect() {
    return this.editorMode === EDITOR_MODE_SELECT;
  }

  get isPanelModeAdd() {
    return this.panelMode === PANEL_MODE_ADD;
  }

  get isPanelModeRemove() {
    return this.panelMode === PANEL_MODE_REMOVE;
  }

  get isPanelModeToggle() {
    return this.panelMode === PANEL_MODE_TOGGLE;
  }

  startPaintPanels() {
    this.enableAllPaintSubModeBtns();

    this.selectInteractionManager.remove();
    this.snapInteractionManager.remove();
    this.dragBoxInteractionManager.add();
    this.paintSelectInteractionManager.add();
    this.modifyInteractionManager.remove();
    this.measureInteractionManager.remove();

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  startSelectMode() {
    this.disableAllPaintSubModeBtns();

    this.selectInteractionManager.add();
    this.snapInteractionManager.remove();
    this.dragBoxInteractionManager.remove();
    this.paintSelectInteractionManager.remove();
    this.modifyInteractionManager.add();
    this.measureInteractionManager.remove();

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  startMeasureMode() {
    this.disableAllPaintSubModeBtns();

    this.selectInteractionManager.remove();
    this.snapInteractionManager.remove();
    this.dragBoxInteractionManager.remove();
    this.paintSelectInteractionManager.remove();
    this.modifyInteractionManager.remove();
    this.measureInteractionManager.add();

    toolbarBtn.showEnabledAndHideDisabledPinnedFlyoutMenus();
  }

  addPanels() {
    this.panelMode = PANEL_MODE_ADD;
  }

  removePanels() {
    this.panelMode = PANEL_MODE_REMOVE;
  }

  togglePanels() {
    this.panelMode = PANEL_MODE_TOGGLE;
  }

  enableAllPaintSubModeBtns() {
    toolbarBtn.enable(this.addPanelsBtnTarget);
    toolbarBtn.enable(this.removePanelsBtnTarget);
    toolbarBtn.enable(this.togglePanelsBtnTarget);
    toolbarBtn.enable(this.panelStatusMenuBtnTarget);
  }

  disableAllPaintSubModeBtns() {
    toolbarBtn.disable(this.addPanelsBtnTarget);
    toolbarBtn.disable(this.removePanelsBtnTarget);
    toolbarBtn.disable(this.togglePanelsBtnTarget);
    toolbarBtn.disable(this.panelStatusMenuBtnTarget);
  }

  azimuthUpdated(currentRotationDegrees, allRsInRp, allRs, newProjectDefault, dragging) {
    this.draggingAzimuth = dragging;

    if (newProjectDefault) {
      if (currentRotationDegrees === this.project.azimuth) return;

      this.project.setAzimuth(currentRotationDegrees);
    }

    this.project.displayableRoofPlanes.forEach((roofPlane) => {
      roofPlane.displayableRoofSections.forEach((roofSection) => {
        if (currentRotationDegrees === roofSection.azimuth) return;

        let shouldUpdate = roofSection.uuid === this.roofSection.uuid;

        if (allRsInRp && roofSection.roofPlane.uuid === this.roofSection.roofPlane.uuid) {
          shouldUpdate = true;
        }

        if (allRs) {
          shouldUpdate = true;
        }

        if (shouldUpdate && currentRotationDegrees !== roofSection.azimuth) {
          logger(`setting azimuth to ${currentRotationDegrees} for ${roofSection.displayIdentifier}`);
          roofSection.setAzimuth(currentRotationDegrees);

          // only adjust the visible array for the roof section being focused on
          const rsFeature = this.mapModelSynchronizer.getFeatureForRoofSection(roofSection);
          const currentLayout = roofSection.layout && clone(roofSection.layout);
          if (roofSection.uuid === this.roofSection.uuid) {
            addLayoutAndArrayForRoofSection(rsFeature, roofSection, this.project, this.mapManager, currentLayout);
          } else {
            addLayoutForRoofSection(rsFeature, roofSection, this.project, this.mapManager, currentLayout);
          }
          if (currentLayout) destroy(currentLayout);
        }
      });
    });

    this.mapManager.rotateTo(this.negateAzimuthRotationRadians);
    setTimeout(() => {
      this.alignViewToSelectedRoofSection(false);
    }, 200);

    this.markDirty();
  }
}
