import DaBase from "../../../../da/map/interaction-managers/snap/base";

export default class RoofSections extends DaBase {
  get mainTargetSource() {
    return this.mapManager.setbacksVectorSource;
  }

  get secondarySource() {
    return this.mapManager.roofSectionsVectorSource;
  }

  get tertiarySource() {
    return this.mapManager.arraysVectorSource;
  }

  get quaternarySource() {
    return this.mapManager.guideLinesVectorSource;
  }

  get quinarySource() {
    return this.mapManager.guideLinesIntersectionCirclesVectorSource;
  }
}
