import MapRoofPlanesAndSectionsController from "./map_roof_planes_and_sections_controller";
import DrawInteractionManager from "../../../bx/map/interaction-managers/draw/obstructions";
import SelectInteractionManager from "../../../bx/map/interaction-managers/select/obstructions";
import SnapInteractionManager from "../../../bx/map/interaction-managers/snap/obstructions";
import TranslateInteractionManager from "../../../bx/map/interaction-managers/translate/obstructions";
import ModifyInteractionManager from "../../../bx/map/interaction-managers/modify/obstructions";
import MeasureInteractionManager from "../../../da/map/interaction-managers/measure/base";
import ShapeGuideLinesInteractionManager from "../../../da/map/interaction-managers/shape-guide-lines/base";

import * as toolbarSelectGroup from "../../../da/layout-editor/helpers/toolbar-select-group";
import { EDITOR_MODE_DRAW } from "../../../da/layout-editor/helpers/toolbar-constants";
import ObstructionsBufferBuilder from "../../../bx/map/obstructions/buffer-builder";
import ObstructionsProximityDetector from "../../../bx/map/obstructions/proximity-detector";
import {
  deleteObstruction,
  rebuildObstructionBuffers,
  reprocessRoofSectionsDueToObstructionChange,
} from "../../../bx/map/modification-helpers/obstruction";
import { removeFeaturesForObstruction } from "../../../bx/map/ol-helpers";

export default class MapObstructionsController extends MapRoofPlanesAndSectionsController {
  static outlets = ["bx--layout-editor--obstruction-heights-form"];

  connect() {
    super.connect();

    this.showBoundingBoxes = false;
  }

  disconnect() {
    super.disconnect();

    this.drawInteractionManager.remove();
  }

  mapSpecificConnect() {
    this.editorMode = toolbarSelectGroup.getState("bxObstructionEditorMode", EDITOR_MODE_DRAW);
  }

  setupMapInteractionManagers() {
    this.drawInteractionManager = new DrawInteractionManager(this);

    this.selectInteractionManager = new SelectInteractionManager(this);

    this.snapInteractionManager = new SnapInteractionManager(this);
    this.snapInteractionManager.add();

    this.translateInteractionManager = new TranslateInteractionManager(this);
    this.modifyInteractionManager = new ModifyInteractionManager(this);

    this.measureInteractionManager = new MeasureInteractionManager(this);
    this.shapeGuideLinesInteractionManager = new ShapeGuideLinesInteractionManager(this);
  }

  setObstructionHeight(uuid, height) {
    const obstruction = this.project.getObstruction(uuid);
    obstruction.setHeight(height);
    if (obstruction.dirty) {
      // mark controller dirty to light up save button
      this.markDirty();
      new ObstructionsBufferBuilder(
        this,
        this.mapManager.map,
        this.mapManager.obstructionsFeatures,
        this.mapManager.obstructionBuffersFeatures,
      );
      reprocessRoofSectionsDueToObstructionChange(this.project);

      new ObstructionsProximityDetector(this.project).findNearbyRoofPlanesAndSectionsForObstruction(obstruction);
    }
  }

  preSaveForPage() {
    this.project.obstructions.forEach((obstruction) => {
      rebuildObstructionBuffers(obstruction, this.mapManager, this.mapModelSynchronizer);
    });
  }

  removeObstruction(obstructionUuid) {
    const obstruction = this.project.getObstruction(obstructionUuid);
    const obstructionFeature = this.mapModelSynchronizer.getFeatureForObstruction(obstruction);
    deleteObstruction(obstructionFeature, this.project, this.mapModelSynchronizer, this.mapManager);
    this.markDirty();
  }
}
