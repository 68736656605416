import { polygon } from "@turf/helpers";
import BufferBuilder from "./buffer-builder";

export default class FeatureExpander extends BufferBuilder {
  constructor(map, feature, outset) {
    super();

    this.map = map;
    this.feature = feature;
    this.outset = outset;
  }

  expand() {
    const translatedFeaturesCollection = this.translateOpenLayersFeaturesToTurf(this.feature);
    const bufferedFeatures = this.buildBufferFeatures(translatedFeaturesCollection, this.outset);
    this.translateTurfFeaturesToModels(bufferedFeatures);
  }

  buildModelsFromTurfFeatures(featuresFromBuffering) {
    this.expandedFeatures = [];
    featuresFromBuffering.forEach((feature) => {
      const polygonFeatures = this.mapMultiPolygon(feature);
      polygonFeatures.forEach((polygonFeature) => {
        this.expandedFeatures.push(polygonFeature);
      });
    });
  }

  get expandedFeature() {
    return this.expandedFeatures[0];
  }
}
