import { Controller } from "@hotwired/stimulus";

import * as animate from "../../../helpers/animate";

export default class extends Controller {
  static targets = [
    "heightInput",
    "heightContainer",
    "template",
    "setAllHeightsContainer",
    "setAllHeightsInput",
    "setAllHeightsLink",
    "obstructionHeightInputsContainer",
    "heightsApplicationMode",
  ];
  static outlets = ["bx--layout-editor--map-obstructions"];

  connect() {
    console.log(this.obstructionsCount());
    if (this.obstructionsCount() > 0) {
      this.setAllHeightsLinkTarget.classList.remove("d-none");
    }
  }

  add(obstruction) {
    const template = this.templateTarget.innerHTML
      .replaceAll("[identifier]", obstruction.identifier)
      .replaceAll("[uuid]", obstruction.uuid);

    this.obstructionHeightInputsContainerTarget.insertAdjacentHTML("beforeend", template);

    animate.show(this.setAllHeightsLinkTarget, { duration: 250 });
  }

  remove(uuid, obstructions) {
    const container = this.#getHeightContainerForUUID(uuid);
    container.remove();

    obstructions.forEach((obstruction) => {
      const container = this.#getHeightContainerForUUID(obstruction.uuid);
      if (container) {
        container.querySelector("label").innerHTML = `Obstruction #${obstruction.identifier}`;
      }
    });

    if (this.obstructionsCount() === 0) {
      animate.hide(this.setAllHeightsLinkTarget, { fadeOut: false });
    }
  }

  obstructionsCount() {
    return this.heightInputTargets.length;
  }

  updateHeightHandler(event) {
    const field = event.currentTarget;

    this.updateHeight(field);
  }

  updateHeight(field) {
    const uuid = field.dataset.identifier;
    const height = this.#extractValidFloat(field.value);

    field.value = height;
    const container = field.closest("[data-identifier='fieldWrapper']");
    if (field.value === "") {
      container.classList.add("field_with_errors");
    } else {
      container.classList.remove("field_with_errors");
    }
    this.bxLayoutEditorMapObstructionsOutlet.setObstructionHeight(uuid, height);
  }

  iconRemove(event) {
    const obstructionUuid = event.params.uuid;
    this.bxLayoutEditorMapObstructionsOutlet.removeObstruction(obstructionUuid);
    this.remove(obstructionUuid, this.bxLayoutEditorMapObstructionsOutlet.project.obstructions);
  }

  openSetAllHeights(event) {
    event.preventDefault();
    animate.hide(this.setAllHeightsLinkTarget, { fadeOut: false });
    animate.show(this.setAllHeightsContainerTarget, { duration: 250 });
    this.setAllHeightsInputTarget.focus();
  }

  setAllHeights(event) {
    event.preventDefault();
    const height = this.setAllHeightsInputTarget.value;
    this.setAllHeightsInputTarget.value = "";
    animate.hide(this.setAllHeightsContainerTarget, { fadeOut: false });
    animate.show(this.setAllHeightsLinkTarget, { duration: 250 });

    const applicationType = this.heightsApplicationModeTargets.find((radio) => radio.checked).value;

    this.heightInputTargets.forEach((input) => {
      if (applicationType === "all" || (applicationType === "empty" && input.value === "")) {
        input.value = height;
        this.updateHeight(input);
      }
    });
  }

  cancelSetAllHeights(event) {
    event.preventDefault();
    animate.show(this.setAllHeightsLinkTarget, { duration: 250 });
    animate.hide(this.setAllHeightsContainerTarget, { fadeOut: false });
    this.setAllHeightsInputTarget.value = "";
  }

  handleKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.setAllHeights(event);
    }
  }

  #getHeightContainerForUUID(uuid) {
    return this.heightContainerTargets.find((container) => container.dataset.uuid === uuid);
  }

  #extractValidFloat(value) {
    let result = value.replace(/[^\d\.]/g, "");

    // get rid of a second .
    if (result.match(/\.\d*\./)) {
      result = Number.parseFloat(result);
    }

    return result;
  }
}
