import { Draw } from "ol/interaction";

import { drawStyle } from "../../styles/draw";
import { ensureFeatureIsClockwise } from "../../ol-helpers";
import { constraintGeometryFunction, withConstraintMixin } from "../helpers/constraint";
import { logger } from "../../../../helpers/app";

export default class Base {
  constructor(controller) {
    this.controller = controller;
    this.settings = controller.settings;
    this.mapModelSynchronizer = controller.mapModelSynchronizer;
    this.drawBtnTarget = controller.drawBtnTarget;

    this.mapManager = controller.mapManager;
    this.map = this.mapManager.map;

    this.initConstraint();

    this.currentDrawInteraction = undefined;
  }

  // Add polygon drawing interaction to the vector source
  add() {
    this.clearCurrentInteraction();

    this.attachConstraintListeners();

    this.currentDrawInteraction = new Draw({
      source: this.targetDrawingSource,
      type: "Polygon",
      style: (feature) => drawStyle(feature, this.map),
      geometryFunction: (coordinates, geometry) =>
        constraintGeometryFunction(coordinates, geometry, this.map, this.constrainPressed, "Polygon"),
    });

    this.currentDrawInteraction.on("drawstart", this.onDrawStart);
    this.currentDrawInteraction.on("drawstart", (e) => {
      const { feature } = e;

      feature.set("dataType", this.featureDataType);
      feature.set("drawing", true);
      this.currentDrawInteraction.feature = feature;
    });
    this.currentDrawInteraction.on("drawstart", this.addFeatureToShapeGuideLines);

    this.mapManager.onClick(this.onDrawClick);
    this.mapManager.onClick(this.updateFeatureInShapeGuideLines);

    this.currentDrawInteraction.on("drawend", (e) => {
      e.feature.set("drawing", false);
      this.currentDrawInteraction.feature = undefined;
    });
    this.currentDrawInteraction.on("drawend", this.constraintPolygonOnDrawEnd);
    this.currentDrawInteraction.on("drawend", this.removeFeatureFromShapeGuideLines);
    this.currentDrawInteraction.on("drawend", this.onDrawEnd);

    this.map.addInteraction(this.currentDrawInteraction);
    this.controller.snapInteractionManager.refresh();
  }

  finish() {
    if (!this.currentDrawInteraction) return;

    this.currentDrawInteraction.finishDrawing();

    this.controller.project.clearDrawingCoordinates();

    // If we end up with only a line, remove it
    const features = this.targetDrawingSource.getFeatures();
    const lastFeature = features[features.length - 1];
    if (lastFeature && lastFeature.getGeometry().getArea() === 0) {
      this.targetDrawingSource.removeFeature(lastFeature);
    }
  }

  remove() {
    this.detachConstraintListeners();

    this.controller.project.clearDrawingCoordinates();

    if (
      this.controller.shapeGuideLinesInteractionManager &&
      !this.settings.showShapeGuideLinesAlways &&
      this.controller.drawInteractionManager.currentDrawInteraction?.feature
    ) {
      const feature = this.controller.drawInteractionManager.currentDrawInteraction.feature;
      this.controller.shapeGuideLinesInteractionManager.remove(feature, "draw");
    }

    this.clearCurrentInteraction();
  }

  clearCurrentInteraction() {
    if (this.currentDrawInteraction) {
      this.mapManager.unClick(this.onDrawClick);
      this.mapManager.unClick(this.updateFeatureInShapeGuideLines);
      this.shapeGuideLinesCall("remove", this.currentDrawInteraction.feature, "clearCurrentInteraction");

      this.map.removeInteraction(this.currentDrawInteraction);
      delete this.currentDrawInteraction;
      this.currentDrawInteraction = undefined;
    }
  }

  shapeGuideLinesCall(method, features, from) {
    if (!this.showShapeGuideLinesWhenDrawing) return;

    this.controller.shapeGuideLinesInteractionManager[method](features, `draw ${from}`);
  }

  get showShapeGuideLinesWhenDrawing() {
    return this.controller.shapeGuideLinesInteractionManager && this.settings.showShapeGuideLinesWhenDrawing;
  }

  reset() {
    this.remove();
    this.add();
  }

  get featureDataType() {
    // override in sub class
  }

  onDrawStart = (_event) => {};

  onDrawClick = (_event) => {};

  onDrawEnd = (event) => {
    const { feature } = event;

    this.controller.project.clearDrawingCoordinates();
    this.featureSpecificDrawEndProcessing(event);

    if (feature.getGeometry().getArea() === 0) {
      feature.set("flaggedForAutoDeleteByVectorSource", true);
      feature.set("dataType", this.featureDataType);

      return;
    }

    // TODO: How can we get a feature with an id?
    if (feature.getId()) {
      console.error("New feature already has an id");
      debugger;
    }

    if (feature.get("uuid")) {
      console.error("New feature already has a UUID");
      debugger;
    }

    ensureFeatureIsClockwise(feature);
    this.createModelFromFeature(feature);

    this.controller.markDirty();
  };

  // onDrawStart
  addFeatureToShapeGuideLines = (event) => {
    this.shapeGuideLinesCall("add", event.feature, "drawstart addFeatureToShapeGuideLines");
  };

  // onDrawClick
  updateFeatureInShapeGuideLines = (_event) => {
    this.shapeGuideLinesCall("update", this.currentDrawInteraction.feature, "drawclick updateFeatureInShapeGuideLines");
  };

  // onDrawEnd
  removeFeatureFromShapeGuideLines = (event) => {
    this.shapeGuideLinesCall("remove", event.feature, "drawend removeFeatureFromShapeGuideLines");
  };

  initializeDrawingFromProjectDrawingCoordinates() {
    const coordinates = this.controller.project.drawingCoordinates;
    if (coordinates.length === 0) return;

    const olCoordinates = coordinates.map((c) => c.toCoordinate);

    this.reset();

    this.currentDrawInteraction.appendCoordinates(olCoordinates);
  }

  createModelFromFeature(_feature) {
    // override in sub class
  }

  featureSpecificDrawEndProcessing(_event) {}
}

Object.assign(Base.prototype, withConstraintMixin);
