import { Controller } from "@hotwired/stimulus";

import * as animate from "../../../helpers/animate";
import AlertErrorManager from "../../../helpers/alert-error-manager";
import { flashlightAndRemoveClass } from "../../../helpers/app";

export function calculateFeetPerPixel({ imageInches, realFeet, imageDpi }) {
  const realInches = realFeet * 12;
  const scaleFactorInchPerInch = realInches / imageInches;
  const feetPerPixel = scaleFactorInchPerInch / imageDpi / 12;

  return feetPerPixel;
}

export default class extends Controller {
  static targets = [
    "imageInchesField",
    "realFeetField",
    "feetPerPixelField",
    "calculateErrorMessage",
    "addToMapErrorMessage",
    "imageDisplay",
    "imageDimensions",
    "dpiDisplay",
    "dpiInfoIcon",
  ];

  static outlets = ["da--layout-editor--scale-building-image"];

  // This is used in a dialog, which is rendered in the DOM and then moved to be a child of the
  // body element, which results in a double connect.  We only want to connect this controller
  // once, so we use this flag to check if we've already connected.
  connectedOnce = false;

  imageData = null;

  connect() {
    if (!this.connectedOnce) {
      this.connectedOnce = true;
      return;
    }

    this.addEnterHandlers();
  }

  disconnect() {
    this.removeEnterHandlers();
  }

  setImage(imageData) {
    this.imageData = imageData;

    this.clearFields();

    this.imageDisplayTarget.src = imageData.imagePath;

    this.imageDimensionsTarget.textContent = `${imageData.width} × ${imageData.height} px`;

    const dpi = imageData.dpi || 72;
    this.dpiDisplayTarget.textContent = `${dpi} DPI`;

    if (dpi !== 72) {
      this.dpiInfoIconTarget.classList.remove("d-none");
    } else {
      this.dpiInfoIconTarget.classList.add("d-none");
    }

    return true;
  }

  addEnterHandlers() {
    this.imageInchesFieldTarget.addEventListener("keydown", this.enterCalculateHandler);
    this.realFeetFieldTarget.addEventListener("keydown", this.enterCalculateHandler);
  }

  enterCalculateHandler = (event) => {
    if (event.key === "Enter") {
      this.calculate();
    }
  };

  removeEnterHandlers() {
    this.imageInchesFieldTarget.removeEventListener("keydown", this.enterCalculateHandler);
    this.realFeetFieldTarget.removeEventListener("keydown", this.enterCalculateHandler);
  }

  calculate() {
    if (!this.isValid) return;

    const feetPerPixel = calculateFeetPerPixel({
      imageInches: this.imageInches,
      realFeet: this.realFeet,
      imageDpi: this.imageDpi,
    });

    this.feetPerPixelFieldTarget.value = feetPerPixel;

    flashlightAndRemoveClass(this.feetPerPixelFieldTarget);
  }

  get imageInches() {
    return parseFloat(this.imageInchesFieldTarget.value);
  }

  get realFeet() {
    return parseFloat(this.realFeetFieldTarget.value);
  }

  get imageDpi() {
    return this.imageData?.dpi || 72;
  }

  get feetPerPixel() {
    return parseFloat(this.feetPerPixelFieldTarget.value);
  }

  get isValid() {
    const errors = new AlertErrorManager(this.calculateErrorMessageTarget);

    if (isNaN(this.imageInches) || this.imageInches === 0) {
      errors.add("Please enter a value for the image scale");
    }

    if (isNaN(this.realFeet) || this.realFeet === 0) {
      errors.add("Please enter a value for the real scale");
    }

    errors.showHide();

    return errors.empty;
  }

  addScaledToMap(_event) {
    const outlet = this.daLayoutEditorScaleBuildingImageOutlet;

    const errors = new AlertErrorManager(this.addToMapErrorMessageTarget);
    const feetPerPixel = this.feetPerPixel;

    if (isNaN(feetPerPixel) || feetPerPixel === 0) {
      errors.add("Please enter a value for the feet per pixel or use the calculator above to calculate the value");
    }

    errors.showHide();

    if (errors.has) return;

    outlet.addManualScaledToMap(feetPerPixel);

    this.clearFields();
  }

  clearFields() {
    this.imageInchesFieldTarget.value = "";
    this.realFeetFieldTarget.value = "";
    this.feetPerPixelFieldTarget.value = "";
  }

  hideCalculateError() {
    animate.hide(this.calculateErrorMessageTarget);
  }

  showCalculateError() {
    animate.show(this.calculateErrorMessageTarget);
  }
}
